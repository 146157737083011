/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {useMemo, useState} from 'react'
import FormControl from '@mui/material/FormControl';
import {Modal} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from "@mui/material/Button";

export default function BaseModal(props) {
    const {clickSave, children, title, buttonCaption} = props
    const [isOpen, setIsOpen] = useState(typeof(props.open) === 'boolean' ? props.open : true);

    const passprops = useMemo(() => {
        const x = {...props}
        delete x.children
        delete x.title
        delete x.buttonCaption
        delete x.clickSave
        delete x.formInvalid
        delete x.loading
        return x
    }, [props]);

    return <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="Modal"
            aria-describedby="Modal"
            {...passprops}
    >
        <Box
            className="ModalInner"
            sx={{maxHeight: "80dvh"}}
        >
            {title ? <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
            </Typography> : ''}
            <Typography id="modal-modal-description" sx={{mt: 2}} component={'div'} variant={'body2'}>
                <FormControl>
                    <Box sx={{overflowY: 'auto', maxHeight: "75dvh", display: "flex", flexDirection: "column"}}>
                        {children}
                    </Box>
                    <Box sx={{textAlign: 'right', marginTop: 3}}>
                        <Button
                            // startIcon={<AddIcon/>}
                            onClick={() => clickSave ? clickSave() : setIsOpen(false)}
                            color="success"
                            disabled={props.formInvalid || false}
                        >{buttonCaption || 'Save'}</Button>
                    </Box>
                </FormControl>
            </Typography>
        </Box>
    </Modal>
}
