import React, {useEffect, useState} from "react"
import {useApiCallback} from "../utils/Api"
import Stack from "@mui/material/Stack"
import useConfigStore from '../utils/useConfigStore'
import useStatsStore from '../utils/useStatsStore'
import {Link as RLink} from "react-router-dom"
import TextField from '@mui/material/TextField'
import {Button} from "@mui/material"
import Typography from "@mui/material/Typography";
import GenericSelect from "./GenericSelect";
import NonPrimaryWarning from "../component/NonPrimaryWarning";

export default function New() {
    const [identity, setIdentity] = useState('')
    const {assigned} = useStatsStore()
    const {primaryHost} = useConfigStore()
    const [type, setType] = useState('air')
    const [result, setResult] = useState('')
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const [valid, setValid] = useState(false)
    const onDone = dd => {
        setResult(
            'New client created\n\n' +
            JSON.stringify(dd, null, 2) +
            '\n\nPlease record this password into the relevant config file, it will not be displayed again.'
        )
        setDone(true)
    }
    const saveConfig = useApiCallback('POST', `/api/identity`, {setLoading, onDone})

    useEffect(() => {
        let dd = identity
        if (type === 'gnd' && !dd.startsWith('IA-')) {
            dd = 'IA-' + dd
        } else if (type !== 'gnd' && dd.startsWith('IA-')) {
            dd = dd.replace(/^IA-/, '')
        }
        if (identity !== dd) {
            setIdentity(dd)
        }
    }, [identity, setIdentity, type])

    useEffect(() => {
        if (!assigned || !identity || identity === 'IA-') {
            setValid(false)
        }
        else if (assigned.hasOwnProperty(identity)) {
            setValid(false)
        } else {
            setValid(true)
        }
    }, [identity, setValid])

    const saveClick = () => {
        saveConfig({identity, type})
    }

    if (primaryHost) {
        return <NonPrimaryWarning/>
    }

    return <Stack>
        <Typography component="h2">New Client</Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
            <GenericSelect
                setValue={setType}
                disabled={done || loading}
                value={type}
                label="Type"
                options={['air', 'gnd']}
            />
            <TextField
                label="Name"
                InputLabelProps={{shrink: true}}
                value={identity}
                disabled={done || loading}
                onChange={(event) => {
                    let dd = event.target.value.toUpperCase().replace(/[^A-Z0-9-]/, '')
                    setIdentity(dd)
                }}
                sx={{width: '300px', margin: 2}}
            />
            <Button onClick={saveClick} disabled={!valid || done || loading}>Create</Button>
            <Button component={RLink} to={`/config/${identity}`} disabled={!done}>Config</Button>
        </Stack>
        <TextField
            label="Status"
            InputLabelProps={{shrink: true}}
            multiline
            value={result}
            InputProps={{
                style: {fontFamily: 'Monospace', fontSize: 16, padding:20, fontWeight: 'bold'},
            }}
            sx={{width: '100%', marginTop:3, backgroundColor: '#333'}}
        />
    </Stack>
}
