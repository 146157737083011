/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {useLoginStore} from "../Login";
import React, {useEffect, useState} from "react";
import {usePeopleStore} from "../utils/usePeopleStore";
import {getServer} from "../utils/server";
import Avatar from "@mui/material/Avatar";
import SmartToyIcon from '@mui/icons-material/SmartToy';

export default function PersonAvatar({person, sx}) {
    const {id} = useLoginStore()
    const {people} = usePeopleStore()
    const [name, setName] = useState(person)
    const srv = getServer()

    useEffect(() => {
        if (id === person) {
            setName('me')
            return
        }
        const n = people[person]?.name
        setName(n ? n : '…')
    }, [id, people, setName, person])

    let src = ''
    let icon = null
    if (name === 'System') {
        icon = <SmartToyIcon/>
    } else {
        src = `${srv}/api/person/avatar/${person}`
    }

    return (
        <Avatar
            variant="circular"
            src={src}
            sx={{bgcolor: 'feature.main', height: '30px', width: '30px', ...sx}}
        >{icon}</Avatar>
    )
}
