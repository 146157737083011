import React, {useState, useEffect, useCallback, useMemo} from "react"
import {useApiCallback} from "../utils/Api"
import 'mapbox-gl/dist/mapbox-gl.css'
import {timeSince} from "../utils/time";
import {DataGridPro} from '@mui/x-data-grid-pro'
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import UploadModal from "./UploadModal";
import {useServerStore} from "../utils/useServerStore";
import useConfigStore from "../utils/useConfigStore";
import NonPrimaryWarning from "../component/NonPrimaryWarning";


export default function Uploads() {
    const [loading, setLoading] = useState(false)
    const {servertime} = useServerStore()
    const {primaryHost} = useConfigStore()
    const [uploads, setUploads] = useState([])
    const [aircrafts, setAircrafts] = useState([])
    const [detail, setDetail] = useState(null)
    const [flights, setFlights] = useState([])

    const every30s = Math.round(servertime / 30)

    const gotUploads = useCallback(dd => {
        const aircrafts = {}
        const uploads = dd.map(d => {
            aircrafts[d['aircraft']] = 1
            return {id: d['client']+'|'+d['contract']+'|'+d['filename'], ...d}
        })
        // sort uploads in ascending order of created_at time
        uploads.sort((x, y) => (x.created_at === y.created_at) ? 0 : (x.created_at < y.created_at ? -1 : 1))

        const flights = []
        const _1HOUR = 3600
        uploads.forEach(u => {
            let found = false
            flights.forEach(f => {
                if (found)
                    return
                if (u.client !== f.client || u.contract !== f.contract || u.aircraft !== f.aircraft)
                    return
                if (u.created_at >= f.start - _1HOUR && u.created_at <= f.end + _1HOUR) {
                    found = true
                    f.unverified += u.verified_at ? 0 : u.size
                    f.verified += u.verified_at ? u.size : 0
                    f.start = Math.min(f.start, u.created_at)
                    f.end = Math.max(f.end, u.created_at)
                    f.duration = f.end - f.start
                    u.flight = f
                    f.id = `${f.aircraft}|${f.client}|${f.contract}|${f.start}`
                    f.errors += u.errors ? 1 : 0
                }
            })
            if (found) return
            const f = {
                id: `${u.aircraft}|${u.client}|${u.contract}|${u.created_at}`,
                client: u.client,
                aircraft: u.aircraft,
                contract: u.contract,
                start: u.created_at,
                end: u.created_at,
                unverified: u.verified_at ? 0 : u.size,
                verified: u.verified_at ? u.size : 0,
                duration: 0,
                errors: u.errors ? 1 : 0
            }
            u.flight = f
            flights.push(f)
        })
        uploads.forEach(d => {d.flight = d.flight.id})
        setAircrafts(Object.keys(aircrafts))
        setUploads(uploads)
        setFlights(flights)
    }, [setUploads, setAircrafts])

    const fetchUploads = useApiCallback('GET', `/api/uploads/96h`, {setLoading, onDone: gotUploads})
    useEffect(() => fetchUploads(), [every30s, fetchUploads])

    const filesize = useCallback(params => {
        let size = params.value
        if (size < 1024)
            return `${size} B`
        size = size >> 10
        if (size < 1024)
            return `${size} KB`
        size = size >> 10
        return `${size} MB`
    }, [])

    const verifiedAt = useCallback(params => {
        if (params.value) {
            return <Typography sx={{color:'#0c0', fontWeight:'bold'}}>{timeSince(servertime, params.value)}</Typography>
        }
        return <Typography sx={{color:'#f44'}}>unverified</Typography>
    }, [servertime])

    const inspect = useCallback(params => {
        return <Button sx={{margin:0, padding:0}} onClick={() => setDetail(params.row)}>🔎</Button>
    }, [])

    const duration = useCallback(params => timeSince(params.row.end, params.row.start), [])

    const verifiedPercent = useCallback(params => {
        const percent = Math.round(100 * (params.row.verified / (params.row.verified+params.row.unverified)))
        const color = (percent >= 98) ? '#0c0' : (percent > 50 ? '#fa0' : '#f44')
        return <Typography sx={{color:color}}>{percent}%</Typography>
    }, [])

    const errorsRender = useCallback(params => {
        if (!params.value) return ''
        return <Typography sx={{color:'#f44', fontWeight:'bold'}}>{params.value} errors</Typography>
    }, [])

    const toIsoTime = useCallback(params => {
        try {
            return (new Date(params.value * 1000)).toISOString()
        } catch (e) {
            console.warn('invalid time value', params.value)
            return params.value
        }
    }, [])

    const flightsColumnDefinition = useMemo(() => {
        return [
            {field: 'client', headerName: 'Client', flex: 1, },
            {field: 'aircraft', headerName: 'Aircraft', flex: 1, },
            {field: 'contract', headerName: 'Contract', flex: 1, },
            {field: 'start', headerName: 'Start', flex: 1.6, renderCell: toIsoTime},
            {field: 'end', headerName: 'End', flex: 1, renderCell: (params) => timeSince(servertime, params.value)},
            {field: 'duration', headerName: 'Duration', flex: 1, renderCell: duration},
            {field: 'errors', headerName: 'Errors', flex: 1, renderCell: errorsRender},
            {field: 'verified', headerName: 'Verified', flex: 1,  renderCell: verifiedPercent},
        ]
    }, [duration, errorsRender, servertime, toIsoTime, verifiedPercent])

    const columnDefinition = useMemo(() => {
        return [
            {field: 'flight', headerName: 'Flight', flex: 0},
            {field: 'client', headerName: 'Client', flex: 0.7},
            {field: 'aircraft', headerName: 'Aircraft', flex: 0.7},
            {field: 'contract', headerName: 'Contract', flex: 0.7},
            {field: 'open', headerName: 'Inspect', flex: 0.5, renderCell: inspect},
            {field: 'filename', headerName: 'Filename', flex: 1.4},
            {field: 'size', headerName: 'Size', flex: 0.5, renderCell: filesize},
            {field: 'created_at', headerName: 'Created', flex: 1, renderCell: toIsoTime},
            {field: 'verified_at', headerName: 'Verified', flex: 1, renderCell: verifiedAt},
            {field: 'errors', headerName: 'Errors', flex: 1},
        ]
    }, [filesize, inspect, toIsoTime, verifiedAt])

    const [selected, setSelected] = useState([])
    const [filterModel, setFilterModel] = useState({items:[]})
    useEffect(() => {
        console.log('SELECTED', selected)
        setFilterModel({
            items: selected ? [{field: 'flight', operator: 'equals', value: selected[0]}] : []
        })
    }, [selected, setFilterModel])

    // if (primaryHost) {
    //     return <NonPrimaryWarning/>
    // }

    return <div style={{height: 'calc(100vh - 200px)', width: '100%'}}>
        {detail && <UploadModal detail={detail} onClose={() => setDetail(null)}/>}
        <Typography variant="h5">Flights (within last 96h)</Typography>
        <DataGridPro
            sx={{height:'50%'}}
            hideFooter={true}
            loading={loading}
            onRowSelectionModelChange={setSelected}
            rows={flights}
            disableMultipleRowSelection={true}
            columns={flightsColumnDefinition}
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    end: false,
                  },
                },
                sorting: {
                    sortModel: [{field: 'start', sort: 'desc'}],
                },
            }}
        />
        {selected.length ? <Typography variant="h5">Files collected on the selected flight</Typography> : ''}
        {selected.length ? <DataGridPro
            sx={{height:'50%'}}
            hideFooter={true}
            loading={loading}
            rows={uploads}
            disableMultipleRowSelection={true}
            columns={columnDefinition}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    flight: false,
                      client: false,
                      aircraft: false,
                      contract: false,
                  },
                },
                sorting: {
                    sortModel: [{field: 'filename', sort: 'desc'}],
                },
            }}
        /> : ''}
    </div>
}
