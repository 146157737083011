import Grid from "@mui/material/Grid";
import Flags from "./Flags";
import Link from "@mui/material/Link";
import {useEffect, useState} from "react";
import useStatsStore from "../utils/useStatsStore";

export default function StatusRow({identity, setSelected, selected, showClient=false}) {
    const {assignments} = useStatsStore()

    const [vars, setVars] = useState(['','','',''])
    useEffect(() => {
        assignments.forEach(vars => {
            const [gnd, air, , ] = vars
            if (gnd === identity || air === identity) {
                setVars(vars)
            }
        })
        if (!vars || vars.length !== 4)
            setVars(['','','',''])
    }, [assignments, identity, vars])

    const [gnd, air, client, contract] = vars
    if (!gnd && !air) return null
    const k = `${gnd} ${air}`
    const gsel = selected === gnd ? {fontWeight:'bold', color:'#fa0'} : {}
    const asel = selected === air ? {fontWeight:'bold', color:'#fa0'} : {}

    const linkStyle = {
        color: 'white',
        cursor: 'pointer',
        fontWeight: 'normal'
    }

    const cl = showClient ? (client || '~') + ' ' : ''

    const setAir = () => setSelected(air)
    const setGnd = () => setSelected(gnd)

    return <Grid columns={5} wrap="nowrap" key={k} item container xs={12} sx={{whiteSpace: 'nowrap'}} alignItems="center">
        <Grid item xs={1}><Flags identity={air}/></Grid>
        <Grid item xs={1}><Link sx={{...linkStyle, ...asel}} onClick={setAir}>{air}</Link></Grid>
        <Grid item xs={1}>{cl}{contract}</Grid>
        <Grid item xs={1}><Link sx={{...linkStyle, ...gsel}} onClick={setGnd}>{gnd}</Link></Grid>
        <Grid item xs={1}><Flags identity={gnd}/></Grid>
    </Grid>
}
