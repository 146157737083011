import React, {useCallback, useEffect, useMemo, useState} from "react";
import useConfigStore from '../utils/useConfigStore'
import useStatsStore from '../utils/useStatsStore'
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "@mui/material";
import { Link as RLink } from 'react-router-dom'
import Stack from "@mui/material/Stack";
import {timeSinceInt} from "../utils/time";
import {iptolong, longtoip} from "../utils/ip";
import Ago from './Ago'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import useViewStore from "../utils/useViewStore";
import StatusRow from "./StatusRow";
import Grid from "@mui/material/Grid";
import {useServerStore} from "../utils/useServerStore";


const getIpName = (ip, ipmap) => {
    if ('string' === typeof ipmap[ip]) {
        return ipmap[ip];
    }
    if (!ip)
        return false
    try {
        const iplong = iptolong(ip);
        let cidr = 32;
        while (cidr > 8) {
            const ipcidr = longtoip(iplong & (0xffffffff << (32-cidr))) + '/' + cidr;
            if ('string' === typeof ipmap[ipcidr]) {
                return ipmap[ip] = ipmap[ipcidr];
            }
            cidr--;
        }
    } catch (e) {
        console.error('bad ip', ip)
    }
    return false
}


function Header({identity}) {
    const {lastseen, lastseenip} = useStatsStore()
    const {ipmap} = useConfigStore()

    const obj = {
        ip: '',
        ipname: '',
        at: ''
    }
    if (lastseen.hasOwnProperty(identity))
        obj.at = lastseen[identity]

    if (lastseenip.hasOwnProperty(identity)) {
        obj.ip = lastseenip[identity]
        obj.ipname = getIpName(obj.ip, ipmap) || 'unknown'
    }

    return <Box sx={{padding:4}}>
        <code>last seen: <Ago at={obj.at}/>
        <br/>
        origin ip: {obj.ip} ({obj.ipname})</code>
    </Box>
}


export function Stat({identity, module, showTitle=false}) {
    const statsStore = useStatsStore()
    const [modulestats, setModulestats] = useState('')
    const [timeSincestyle, setTimeSincestyle] = useState({})
    const [showOld, setShowOld] = useState(false)
    const [beat, setBeat] = useState('beat')
    const {servertime} = useServerStore()

    const modules = statsStore.stats[identity]
    const stats = useMemo(() => modules ? modules[module] : [], [modules, module])
    const lastseen = stats?.at
    const since = timeSinceInt(servertime, lastseen)

    useEffect(() => {
        setBeat('beat')
        const tt = setTimeout(() => setBeat('beat beat-on'), 10)
        return () => clearTimeout(tt)
    }, [setBeat, stats])

    const blockstyle = useMemo(() => {
        const timeSincestyle = {}
        const blockstyle = {}
        if (since < 20) {
            // very recently updated
            timeSincestyle.color = '#0a0'
            timeSincestyle.fontWeight = 'bold'
            blockstyle.backgroundColor = '#7777'
        } else if (since < 120) {
            // updated semi-recently
            blockstyle.color = '#aaa'
            blockstyle.backgroundColor = '#7775'
        } else {
            // infrequently updated
            blockstyle.color = '#888'
            blockstyle.backgroundColor = '#7773'
        }
        setTimeSincestyle(timeSincestyle)
        return blockstyle;
    }, [since])

    const tooOld = useMemo(() => servertime - lastseen > 60*60*24*30, [lastseen, servertime]);

    useEffect(() => {
        if (!stats) {
            setModulestats('')
            return
        }
        const data = ('undefined' !== typeof stats['data']) ? stats['data'] : stats
        const ms = JSON.stringify(data, null, 2)
            .replace(/^[{[]\n/, '\n')
            .replace(/\n[}\]]$/, '\n')
            .replace(/",?[\n|$]/g, '\n')
            .replace(/\n( *)"([^":]+)":/g, '\n$1$2:')
            .replace(/: "/g, ': ')
            .replace(/\n( *)"/g, '\n$1')
            .replace(/^\n/g, '')
        setModulestats(ms)
    }, [setModulestats, stats])

    const showOldBtn = <Stack>
        <Typography
            style={{color:'#666', fontSize:12, fontStyle:'italic', cursor:'pointer'}}
            onClick={() => setShowOld(true)}
        >
            Record hidden due to age, click to show
        </Typography>
    </Stack>

    // submodules should be much closer to their base module so they are "together"
    return <Stack className={beat} style={{marginTop: '0px', ...blockstyle}}>
        <div>
            <code style={{marginLeft: '8px', lineHeight: '3em'}}>
                {showTitle ? showTitle : module.split('_').pop()}
            </code>
            <span style={{float: 'right', ...timeSincestyle}}>
                <Ago at={lastseen}/>
            </span>
        </div>
        <Pre>
            {tooOld && !showOld ? showOldBtn : modulestats}
        </Pre>
    </Stack>
}

export function Pre({children}) {
    return <pre style={{margin: 0, width:'100%', overflowX: 'auto', padding:'6px 0', borderLeft: '24px solid #fff1'}}>
        {children}
    </pre>
}


export default function Stats() {
    const {identity} = useParams()
    const statsStore = useStatsStore()
    const navigate = useNavigate()
    const [modules, setModules] = useState({})
    const {filter, setFilter} = useViewStore()
    const stats = statsStore.stats[identity]
    const setIdentity = useCallback(ident => navigate(`/stats/${ident}`), [navigate])

    useEffect(() => {
        if (!stats) return
        const basemodules = {}
        filter.split(' ').forEach(filter => {
            Object.keys(stats).sort().forEach(module => {
                if (filter && module.indexOf(filter) === -1)
                    return
                if (filter.startsWith('"') && filter.endsWith('"') && filter !== module)
                    return
                const basemodulename = module.split('_')[0]
                if (!basemodules.hasOwnProperty(basemodulename)) {
                    basemodules[basemodulename] = {}
                }
                basemodules[basemodulename][module] = stats.module
            })
        })
        setModules(basemodules)
    }, [stats, setModules, filter, identity])

    return <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
        <Stack sx={{mt:2}} direction="row" justifyContent="center" spacing={1} alignItems="center">
            <Typography component="h2">{identity}</Typography>
            <Button component={RLink} to={`/video/${identity}`}>Video</Button>
            <Button component={RLink} to={`/config/${identity}`}>Config</Button>
            <TextField
                label="Filter"
                InputLabelProps={{shrink: true}}
                value={filter}
                onChange={(event) => {
                    setFilter(event.target.value)
                }}
                sx={{width: '300px', margin: 2}}
            />
        </Stack>
        <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
            fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
            <Grid item container xs={12} spacing={0}>
                <StatusRow setSelected={setIdentity} identity={identity} selected={identity} showClient={true}/>
            </Grid>
        </Grid>
        <Box style={{height: 'calc(100vh - 155px)', width: '100%', overflowY:'auto'}}>
            <Header identity={identity}/>
            {modules ? Object.keys(modules).map(k =>
                <div key={k} style={{marginTop:'30px', backgroundColor: '#fff1'}}>
                    {Object.keys(modules[k]).map(kk =>
                        <Stat key={kk} identity={identity} module={kk} />
                    )}
                </div>
            ) : ''}
        </Box>
    </Stack>
}
