import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import useStatsStore from "../utils/useStatsStore"
import {Stat} from "./Stats";


export default function Compare() {
    const [name, setName] = useState('')
    const {stats} = useStatsStore()
    const [out, setOut] = useState([])

    useEffect(() => {
        if (!stats) {
            return
        }
        const out = []
        name.split(' ').forEach(filter => {
            if (filter.length < 3) {
                console.log('a')
                return
            }
            Object.keys(stats).forEach(identity => {
                Object.keys(stats[identity]).forEach(module => {
                    if (filter.startsWith('"') && filter.endsWith('"')) {
                        if (filter !== `"${module}"`) {
                            console.log('c', filter, module)
                            return
                        }
                    } else {
                        if (module.indexOf(filter) === -1) {
                            console.log('b')
                            return
                        }
                    }
                    if (out.indexOf(module) > -1) {
                        console.log('d')
                        return
                    }
                    console.log('included:', module)
                    out.push(module)
                })
            })
        })
        setOut(out)
    }, [stats, name, setOut])

    return <Stack justifyContent="center" alignItems="start" >
        <Stack direction="row" alignItems="center">
            <TextField
                label="Name"
                InputLabelProps={{shrink: true}}
                value={name}
                onChange={(event) => {
                    setName(event.target.value.toLowerCase())
                }}
                sx={{width: '300px', margin: 2}}
            />
        </Stack>
        <br/>
        {Object.keys(stats).map(identity =>
            <Stack direction="column" sx={{width: '100%'}}>
                {Object.keys(stats[identity]).map(module => {
                    if (name.length < 3) return <></>
                    if (out.indexOf(module) === -1) return <></>
                    return <Stack key={module} item xs={3} direction="column" spacing={0} sx={{width: '100%', m:2}}>
                        <Stat identity={identity} module={module} showTitle={identity + ' - ' + module}/>
                    </Stack>
                })}
            </Stack>
        )}
    </Stack>
}
