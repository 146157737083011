/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {create} from "zustand";

export const useServerStore = create((set) => ({
    servertime: Math.round((Date.now() / 1000)),
    servertimediff: 0,
    setServertime: (servertime) => set({servertime}),
    setServertimediff: (servertimediff) => set({servertimediff}),
    devMode: '1' === localStorage.getItem(`devmode`),
    setDevMode: (devMode) => set(() => {
        localStorage.setItem('devmode', devMode ? '1' : '0')
        return {devMode}
    }),
}))
