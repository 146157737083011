/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {isNumber} from "@mui/x-data-grid-pro/internals";

export function timeSinceToStr(s) {
    if (!s) {
        return 'never'
    }
    const d = 24 * 60 * 60
    const h = 60 * 60
    const m = 60
    if (s >= d * 2) return Math.floor(s / d) + 'd'
    if (s >= h * 2) return Math.floor(s / h) + 'h'
    if (s >= m * 2) return Math.floor(s / m) + 'm'
    return s + 's'
}

export function timeSinceInt(servertime, at) {
    const now = Math.round(servertime);
    const dd = Math.round(at);
    return Math.max(0, now - dd);
}

export function timeSince(servertime, at) {
    if (at === null || at === 0) return '-'
    const timediff = timeSinceInt(servertime, at)
    return timeSinceZero(timediff)
}

export function timeSinceZero(timediff) {
    if (timediff < 60)
        return `${timediff}s`;
    let time_s = timediff % 60;
    let time_m = Math.floor(timediff / 60) % 60;
    let time_h = Math.floor(timediff / (60 * 60)) % 24;
    let time_d = Math.floor(timediff / (60 * 60 * 24));
    time_s = time_s || time_m || time_h || time_d ? `${time_s}s` : '';
    time_m = time_m || time_h || time_d ? `${time_m}m` : '';
    time_h = time_h || time_d ? `${time_h}h` : '';
    time_d = time_d ? `${time_d}d` : '';

    let timestr = ''
    if (time_d) {
        timestr = `${time_d} ${time_h}`;
    } else if (time_h) {
        timestr = `${time_h} ${time_m}`;
    } else if (time_s || time_m) {
        timestr = `${time_m} ${time_s}`;
    } else {
        timestr = '-'
    }
    return timestr;
}

export function timeStrToMinutes(v) {
    if (v.hasOwnProperty('target')) {
        v = v.target.value
    }
    try {
        const hhmm = `${v}`.split(':')
        console.log(' inputTimeStrToMinutes', hhmm, v)

        if (hhmm.length !== 2)
            return parseInt(hhmm[0]) * 60
        return (parseInt(hhmm[0]) * 60) + parseInt(hhmm[1])
    } catch (e) {
        console.log('error inputTimeStrToMinutes', e, v)
        return null
    }
}

export function minutesToTimeStr(minutes) {
    if (typeof(minutes) === 'undefined' || minutes === null || minutes === '') {
        return '-'
    }
    if (typeof minutes === 'object' && typeof minutes.value !== 'undefined') {
        minutes = minutes.value;
    }
    if (!isNumber(minutes)) {
        return '-';
    }
    try {
        const h = Math.floor(minutes / 60)
        const m = (''+(minutes % 60)).padStart(2, '0')
        return [h,m].join(':')
    } catch (e) {
        return null
    }
}
