/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {useState, useEffect, useCallback, useMemo} from "react";
import {useApiCallback} from "../utils/Api";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import GenericSelect from "../component/GenericSelect";
import {useLoginStore} from "../Login";
import {useAppConfigStore} from "../AppConfigStore";
import Box from "@mui/material/Box";
import {cellDefaults, DataGrid} from "../component/DataGrid";
import {useNavigate} from "react-router-dom";


const DEFAULT_PERMISSIONS = {
    'View ACLs': [
        "GET /api/acl*",
        "GET /api/acl",
        "GET /api/groups",
        "GET #/acl",
    ],
    'Set ACLs': [
        "POST /api/acl*",
        "POST /api/acl",
        "DELETE /api/acl*",
        "GET #/acl",
        "GET /api/acl*",
        "GET /api/acl",
        "GET /api/groups",
        "GET /api/apimap",
    ]
}


export default function Acl() {
    const {canUser, canGroup, permissions} = useLoginStore()
    const configStore = useAppConfigStore()

    const [loading, setLoading] = useState(false)
    const [acls, setAcls] = useState([])
    const [group, setGroup] = useState('')
    const [authority, setAuthority] = useState('')
    const [path, setPath] = useState('')
    const [paths, setPaths] = useState([])
    const [disableDel, setDisableDel] = useState(true)
    const [disableAdd, setDisableAdd] = useState(true)
    const [groupEmails, setGroupEmails] = useState({})
    const [apiMap, setApiMap] = useState([])
    const [apiHints, setApiHints] = useState([])
    const [canSetAcl, setCanSetAcl] = useState(false)
    const [aclSelection, setAclSelection] = useState([])
    const [endpointSelection, setEndpointSelection] = useState([])
    const [focus, setFocus] = useState('')
    const [permission, setPermission] = useState('')

    const gotAcls = useCallback(dd => setAcls(dd.map(d => ({
        id: d.group + ' ' + d.authority + ' ' + d.method + ' ' + d.path,
        ...d
    }))), [setAcls])

    const getAclUpdateData = useCallback(() => {
        if (!group || !authority || !paths)
            return {}
        return paths.map(path => ({
            group,
            authority,
            method: path.split(' ')[0],
            path: path.split(' ')[1]
        }))
    }, [group, authority, paths])

    const fetchAcl = useApiCallback('GET', `/api/acl`, {setLoading, onDone: gotAcls})
    const addAcl = useApiCallback('POST', `/api/acl`, {
        data: getAclUpdateData(),
        setLoading,
        onDone: fetchAcl
    })
    const removeAcl = useApiCallback('DELETE', `/api/acl`, {
        data: getAclUpdateData(),
        setLoading,
        onDone: fetchAcl
    })
    useEffect(fetchAcl, [fetchAcl, permissions])

    const fetchGroupEmails = useApiCallback('GET', `/api/groups`, {setLoading, onDone: setGroupEmails})
    useEffect(fetchGroupEmails, [fetchGroupEmails, permissions])

    const gotApiMap = useCallback(apiMap => {
        // Add the UI pages ( eg #/acl ) to the list as this doesnt come from the server
        configStore.pages.forEach(endpoint => {
            const [,path,] = endpoint
            apiMap.push('GET #'+path)
        })
        setApiMap(apiMap)
    }, [setApiMap, configStore])
    const fetchApiMap = useApiCallback('GET', `/api/apimap`, {setLoading, onDone: gotApiMap})
    useEffect(fetchApiMap, [fetchApiMap, permissions])

    useEffect(() => {
        if (focus !== 'left') {
            return;
        }
        if (!aclSelection.length) {
            return;
        }
        const found = acls.filter(acl => aclSelection.indexOf(acl.id) > -1);
        const paths = found.map(acl => acl.method + ' ' + acl.path);
        if (paths.length > 1) {
            setPath(`${paths.length} paths selected`);
        } else if(found.length) {
            setGroup(found[0].group);
            setAuthority(found[0].authority);
            setPath(paths[0]);
        }
        setPaths(paths);
        console.log('ASDASD', 'set paths from left', paths)
        // dont clear existing selections or it clears boxes when a row is deleted
    }, [aclSelection, focus, acls, setGroup, setAuthority, setPaths, setPath, endpointSelection.length])

    useEffect(() => {
        if (focus !== 'right') {
            return;
        }
        if (!endpointSelection.length) {
            return;
        }
        // Check for click-selection from the endpoints list table
        const paths = apiHints.map(apihint => {
            if (endpointSelection.indexOf(apihint.id) > -1) {
                return apihint.method + ' ' + apihint.path
            }
            return null
        }).filter(a => a !== null)
        if (paths.length > 1) {
            setPath(`${paths.length} paths selected`);
        } else {
            setPath(paths[0]);
        }
        setPaths(paths)
        console.log('ASDASD', 'set right paths', paths)
    }, [endpointSelection, focus, setPath, setPaths, apiHints, aclSelection.length])

    useEffect(() => {
        const permissions = []
        acls.forEach(acl => {
            if (acl.group === group && acl.authority === authority) {
                permissions.push(acl)
            }
        })
        const newhints = apiMap.map(endpoint => {
            const [m, p] = endpoint.split(' ')
            return {id: endpoint, endpoint, method: m, path: p, granted: canGroup(m, p, permissions)}
        }).filter(a => a.endpoint)
        setApiHints(newhints)
    }, [acls, group, authority, path, apiMap, configStore.pages, setApiHints, canGroup])

    useEffect(() => setCanSetAcl(canUser('POST', '/api/acl')), [permissions, canUser]);

    useEffect(() => {
        const id = group + ' ' + authority + ' ' + path
        const ids = acls.map(acl => acl.id)
        console.log('authority && group && paths.length', authority, group, paths.length)
        setDisableDel((!paths.length && ids.indexOf(id) === -1) || !authority || !group)
        setDisableAdd(ids.indexOf(id) > -1 || !authority || !group || !paths.length)
    }, [acls, path, group, authority, paths.length, setDisableDel, setDisableAdd])

    const aclSubject = group + ' ' + authority

    useEffect(() => {
        if (focus === 'top' && !path.endsWith('paths selected')) {
            setPaths([path])
        }
    }, [path, focus])

    const appPermissions = useMemo(() => {
        if (configStore.hasOwnProperty('permissions')) {
            return {...configStore.permissions, ...DEFAULT_PERMISSIONS}
        }
        return DEFAULT_PERMISSIONS
    }, [configStore])

    useEffect(() => {
        // Select the appropriate ACLs for the permission
        if (!permission)
            return

        const paths = appPermissions[permission]
        if (paths.length > 1) {
            setPath(`${paths.length} paths selected`);
        } else {
            setPath(paths[0]);
        }

        setPaths(paths)
        setFocus('permissions')
        setEndpointSelection(paths)
    }, [appPermissions, permission])

    return <Stack justifyContent="center" alignItems="start">
        {canSetAcl ? <>
        <Grid container alignItems="center">
            <Grid item xs={8} md={4}>
                <GenericSelect
                    setValue={setGroup}
                    value={group}
                    label="Group Email"
                    options={Object.keys(groupEmails)}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={4} md={2}>
                <GenericSelect
                    setValue={setAuthority}
                    value={authority}
                    label="Authority"
                    options={['member', 'manager', 'owner']}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={8} md={4}>
                <GenericSelect
                    setValue={setPath}
                    custom={true}
                    value={path}
                    label="Path"
                    options={apiMap}
                    disabled={loading}
                    onFocus={() => setFocus('top')}
                />
            </Grid>
            <Grid item md={1} xs={2} alignItems="center" justifyItems="center">
                <Button sx={{width: '100%'}} disabled={loading || disableAdd} onClick={() => addAcl()}>Add</Button>
            </Grid>
            <Grid item md={1} xs={2} alignItems="center" justifyItems="center">
                <Button sx={{width: '100%'}} disabled={loading || disableDel} onClick={() => removeAcl()}>Delete</Button>
            </Grid>
        </Grid>
        </> : ''}
            <Grid container>
                <Grid item xs={12} md={8} sx={{height: {md:'calc(100dvh - 145px)', xs:'calc(50vh - 90px)'}}}>
                    <AclGrid
                        wrapperStyle={{height: '100%'}}
                        selected={aclSelection}
                        setSelected={setAclSelection}
                        onFocus={() => setFocus('left')}
                        {...{acls, loading, group, authority, path,}}
                    />
                </Grid>

                <Grid item xs={12} md={4} sx={{height: {md:'calc(100dvh - 195px)', xs:'calc(50vh - 145px)'}}}>
                    <GenericSelect
                        setValue={setPermission}
                        custom={false}
                        onKeyDown={(e) => {e.preventDefault();}}
                        value={permission}
                        label="Permission"
                        options={Object.keys(appPermissions)}
                        disabled={loading}
                    />
                    <AclGroupStatusGrid
                        wrapperStyle={{height: '100%'}}
                        selected={endpointSelection}
                        setSelected={setEndpointSelection}
                        onFocus={() => setFocus('right')}
                        {...{apiHints, loading, aclSubject}}
                    />
                </Grid>
            </Grid>
    </Stack>
}


const Code = ({sx={}, children}) => {
    sx = {fontFamily: 'monospace', fontSize: {xs:'0.9em', md:'1em'}, ...sx}
    return <Box {...{sx}}>{children}</Box>
}


const AclGrid = ({acls, wrapperStyle, selected, setSelected, loading, onFocus, group, authority, path}) => {
    const navigate = useNavigate()
    const {canUser} = useLoginStore()
    const styleFlex = useCallback(cell => {
        let val = cell.value
        if ((cell.field === 'group' || cell.field === 'authority') &&
            (group !== cell.row.group || authority !== cell.row.authority)) {
            return 'acl_deselected';
        }
        else if (cell.field === 'path' && path !== val) {
            return 'acl_deselected';
        }
        return 'acl_selected'
    }, [group, authority, path])

    const toolbar = useMemo(() => {
        if (!group) return []
        if (!canUser('GET', `/api/group/members/${group}`)) return []
        return <>
            <Button size="small" onClick={() => navigate(`/group/${group}`)} color="info">Group Info…</Button>
        </>
    }, [canUser, group, navigate])

    return <DataGrid
        wrapperStyle={wrapperStyle}
        loading={loading}
        rows={acls}
        onCellClick={onFocus}
        rowSelectionModel={selected}
        onRowSelectionModelChange={setSelected}
        columns={[
            {field: 'group', headerName: 'Group', flex: 4, ...cellDefaults, cellClassName: styleFlex},
            {field: 'authority', headerName: 'Authority', flex: 1, cellClassName: styleFlex},
            {field: 'path', headerName: 'Path', flex: 4, cellClassName: styleFlex,
                valueGetter: cell => cell.row?.method + ' ' + cell?.row?.path,
                renderCell: cell => <code>{cell?.value}</code>,
            },
        ]}
        initialState={{
            sorting: {
                sortModel: [
                    {field:'group', sort:'asc'},
                    {field:'authority', sort:'asc'},
                    {field:'path', sort:'asc'}
                ],
            }
        }}
        toolbar={toolbar}
    />
}

const AclGroupStatusGrid = ({apiHints, wrapperStyle, selected, onFocus, setSelected, loading, aclSubject}) => {
    const disabledRows = useMemo(() => ([
        'GET /api/login',
        'GET /api/logout',
        'GET /api/error',
        'GET /api/health',
        'GET /api/person/avatar/<personid>',
    ]), [])
    const renderEndpoint = useCallback(cell => {
        let icon = cell.row.granted ? '🟢' : '🔴';
        if (disabledRows.indexOf(cell.value) > -1) {
            icon = '🟠'
        }
        return <Code>{icon} {cell.value}</Code>
    }, [disabledRows])

    return <DataGrid
        wrapperStyle={wrapperStyle}
        loading={loading}
        rows={apiHints}
        isRowSelectable={params => disabledRows.indexOf(params.row.endpoint) === -1 }
        onCellClick={onFocus}
        rowSelectionModel={selected}
        onRowSelectionModelChange={setSelected}
        columns={[
            {field: 'endpoint', headerName: aclSubject, flex: 12,renderCell: renderEndpoint},
        ]}
    />
}
