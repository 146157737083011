import React, {useCallback, useEffect, useState} from "react"
import {useApiCallback} from "../utils/Api"
import Stack from "@mui/material/Stack"
import {Link as RLink, useParams} from "react-router-dom"
import TextField from '@mui/material/TextField'
import {Button} from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid";
import StatusRow from "./StatusRow";
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useConfigStore from "../utils/useConfigStore";
import NonPrimaryWarning from "../component/NonPrimaryWarning";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" color="success" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2">{props.value}s</Typography>
            </Box>
        </Box>
    );
}

export default function Config() {
    const params = useParams()
    const {primaryHost, setupLogs, clearSetupLogs} = useConfigStore()
    const [identity, setIdentity] = useState(params.identity)
    const [loading, setLoading] = useState(false)
    const [newName, setNewName] = useState(identity)
    const [code, setCode] = useState('')
    const status = setupLogs[identity];
    const [time, setTime] = useState(0)

    const onDone = useCallback(d => {
        setCode(d)
        setTime(100)
        clearSetupLogs(identity)
    }, [clearSetupLogs, identity])

    const generateCode = useApiCallback('POST', `/api/setupcode/${identity}`, {setLoading, onDone})

    useEffect(() => {
        if (time <= 0 && code) {
            setCode('')
            setTime(0)
            return
        }
        const tm = setInterval(() => setTime(t => Math.max(0, t - 1)), 1000)
        return () => clearInterval(tm)
    }, [time, code, setTime, setCode])

    const doRename = useApiCallback('POST', `/api/rename/${identity}`, {setLoading})

    return <Container>
        <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
            <Stack sx={{mt:2}} direction="row" alignItems="center" spacing={1} justifyContent="center">
                <Typography component="h2">{identity}</Typography>
                <Button component={RLink} to={`/config/${identity}`}>Config</Button>
                <Button component={RLink} to={`/video/${identity}`}>Video</Button>
                <Button component={RLink} to={`/stats/${identity}`}>Stats</Button>
            </Stack>
            <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
                fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
                <Grid item container xs={12} spacing={0}>
                    <StatusRow setSelected={setIdentity} identity={identity} selected={identity} showClient={true}/>
                </Grid>
            </Grid>

            <NonPrimaryWarning/>

            <Stack direction={{xs:"column",md:"row"}} alignItems="top" spacing={4} my={4} justifyContent="center">

                <Stack sx={{mt:2}} direction="column" alignItems="center" spacing={1} justifyContent="right">
                    <Button
                        disabled={primaryHost || loading || newName === identity}
                        onClick={() => doRename({newName})}
                        color='warning'
                    >Rename</Button>
                    <TextField
                        label="Rename"
                        InputLabelProps={{shrink: true}}
                        multiline
                        disabled={primaryHost || loading}
                        value={newName}
                        onChange={e => setNewName(e.target.value)}
                        inputProps={{
                            style: {
                                fontFamily: 'Monospace',
                                fontSize: 36,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                lineHeight: '100%',
                            },
                        }}
                        sx={{backgroundColor: '#333'}}
                    />
                </Stack>

                <Stack sx={{mt:2}} direction="column" alignItems="center" spacing={1} justifyContent="left">
                    <Button
                        disabled={primaryHost || loading}
                        onClick={() => generateCode()}
                        color='success'
                    >Generate Code</Button>
                    <TextField
                        label="Code"
                        InputLabelProps={{shrink: true}}
                        multiline
                        disabled={primaryHost || loading}
                        value={code}
                        inputProps={{
                            style: {
                                fontFamily: 'Monospace',
                                fontSize: 36,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                lineHeight: '100%',
                            },
                        }}
                        sx={{backgroundColor: '#333'}}
                    />
                    <LinearProgressWithLabel value={time} />
                </Stack>

            </Stack>

            <TextField
                label="Live Status"
                InputLabelProps={{shrink: true}}
                multiline
                disabled={true}
                value={status}
                inputProps={{
                    style: {
                        fontFamily: 'Monospace',
                        fontSize: 36,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        lineHeight: '100%',
                    },
                }}
                sx={{backgroundColor: '#333'}}
            />

        </Stack>
    </Container>
}
