/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import {useEffect, useState} from 'react'
import {matchRoutes, useLocation, useNavigate} from 'react-router-dom'

import {Login} from './Login'
import logo_dea from "./logo_dea.svg"
import logo_esao from "./logo_esao.svg"
import {useAppConfigStore} from "./AppConfigStore"
import {useLoginStore} from './Login'
import {SideDrawerOpenBtn} from "./component/SideDrawer";
import {styled} from "@mui/material/styles";
import {create} from "zustand";
import UserMenu from "./component/UserMenu";
import {useApiCallback} from "./utils/Api";
import WaffleMenu from "./component/WaffleMenu";

export const useAppBarStore = create((set) => ({
    onPage: '',
    setOnPage: (onPage) => set({ onPage }),
}))

export default function MyAppBar() {
    const location = useLocation()
    const navigate = useNavigate()
    const loginstore = useLoginStore()
    const {onPage, setOnPage} = useAppBarStore()
    const {pages} = useAppConfigStore()
    const [menuItems, setMenuItems] = useState([])

    // Do the API calls to get data for the month
    const host = window.location.host.includes('localhost') ? 'demo.dea.aero': window.location.host
    const menuItemsUri = `https://${host}/dashboard/api/apps`
    const loadMenuItems = useApiCallback('GET', menuItemsUri, {onDone: setMenuItems, ignoreErrors: true})
    useEffect(() => {
        if (loginstore.user) loadMenuItems()
    }, [loginstore.user, loadMenuItems])

    useEffect(() => {
        if (!loginstore.user) {
            return
        }
        const links = {}
        Object.keys(pages).forEach(k => {
            const [name, link, , options] = pages[k]
            if (link.indexOf(':') > -1) {
                return
            }
            if (options?.hide) {
                return
            }
            if (loginstore.canUser('GET', '#' + link)) {
                links[name] = {name, link}
            }
        })
    }, [loginstore.permissions, pages, loginstore, loginstore.user])

    useEffect(() => {
        const some = Object.keys(pages).some(k => {
            const [name, path, ,] = pages[k]
            const r = matchRoutes([{path}], location)
            if (r) {
                setOnPage(name)
                return true
            }
            return false
        })
        if (!some) {
            // default page name to be set if no other name found, prevents empty page selector except on homepage
            let name = location.pathname.replace(/^\//, '')
            name = name.charAt(0).toUpperCase() + name.slice(1); // capitalise first letter
            setOnPage(name)
        }
    }, [setOnPage, location, location.pathname, pages])

    const logo = (window.location.host.match(/\.esao\.aero(:\d+)?$/)) ? logo_esao : logo_dea;

    return <AppBar postion="sticky" bgcolor="primary.main" enableColorOnDark>
        <Container maxWidth="false">
            <Toolbar disableGutters>
                <SideDrawerOpenBtn/>
                <Logo src={logo} alt="DEA Logo"
                    onClick={() => navigate('/')}
                />
                <Box sx={{pl:3}}>
                    {onPage}
                </Box>
                <Box sx={{flexGrow: 1}}/>
                {loginstore.user && menuItems.length > 0 ? <WaffleMenu menuItems={menuItems}/> : ''}
                <Box>
                    {loginstore.user ? <UserMenu/> : <Login/>}
                </Box>
            </Toolbar>
        </Container>
    </AppBar>
}

const Logo = styled('img')(({theme}) => {
    const isDemo = window.location.hostname.match(/^demo\.|^dev\.|^localhost/);
    const logo = (window.location.host.match(/\.esao\.aero(:\d+)?$/)) ? logo_esao : logo_dea;
    return {
        height: 50,
        width: 100,
        transform: isDemo && logo === logo_dea ? 'scaleY(-1)' : null,
        position: isDemo ? 'relative' : null,
        filter: [
            theme.palette.mode === 'dark' ? 'invert(1.0)' : '',
            isDemo ? 'drop-shadow(0px 0px 5px red)' : '',
        ].join(' '),
    }
});
