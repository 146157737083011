import {create} from 'zustand'

export const useGarminStore = create((set, get) => ({
    events: [],
    latestEvents: {},
    addEvent: (event) => {
        const {events, latestEvents} = get()

        const cur = latestEvents[event.imei] || null

        if (cur && cur.at >= event.at) {
            // the event is older than the latest event for this imei, so we ignore it.
            // it's not worth the processing effort to add it into the correct place in the array.
            return
        }

        let found = false
        events.forEach(e => {
            found = found || (e.imei === event.imei && e.at === event.at)
        })
        if (!found) {
            set({events: [...events, event]})
        }

        if (!cur || cur.at < event.at) {
            const newCurPos = {...latestEvents}
            newCurPos[event.imei] = event
            set({latestEvents: newCurPos})
        }
    },
    setEvents: (events) => {
        set({events})
        const latest = {}
        events.forEach(e => {
            const dd = latest[e.imei] || null
            if (dd && dd.at > e.at) return
            latest[e.imei] = e
        })
        set({latestEvents: latest})
        console.log('LATEST', latest)
    },
}))
