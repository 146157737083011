/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import {ListItemIcon, ListItemText, Typography} from '@mui/material';
import Stack from "@mui/material/Stack";
import MenuItem from '@mui/material/MenuItem';

const GridMenuItem = ({icon, label}) => (
    <MenuItem sx={{justifyContent: "center", borderRadius: "24px", padding: "10px"}}>
        <Stack sx={{textWrap: "wrap"}}>
            <ListItemIcon sx={{justifyContent: "center", padding: "10px", '& img': {height: '40px', width: '40px'}}}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body2" color="text.primary" align="center" fontSize="0.85rem" flexWrap="wrap">
                {label}
            </Typography>}
            />
        </Stack>
    </MenuItem>
);

export default GridMenuItem;
