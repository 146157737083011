import {Link as RLink, useNavigate} from 'react-router-dom'
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useApiCallback} from "../utils/Api"
import {useLoginStore} from "../Login"
import Grid from "@mui/material/Grid"
import {ExampleGreenFlag, ExampleRedFlag} from "./Flags"
import Stack from "@mui/material/Stack"
import {Button} from "@mui/material"
import ContractSelect from "./ContractSelect"
import {useToastStore} from "../Toast"
import GenericSelect from "./GenericSelect"
import Video from "./Video"
import Typography from "@mui/material/Typography"
import StatusRow from './StatusRow'
import useStatsStore from '../utils/useStatsStore'
import Box from "@mui/material/Box";
import {create} from "zustand";
import useConfigStore from "../utils/useConfigStore";
import NonPrimaryWarning from "../component/NonPrimaryWarning";
import Link from "@mui/material/Link";


const Legend = () => (
    <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="p" sx={{textAlign:'left', maxWidth:700}}>
            <ul style={{listStyleType:'none'}}>
                <li>When an aircraft/terminal status is updated, the status area will blink briefly.</li>
                <li><ExampleRedFlag title='INIT'/> = Missing status info, system probably in startup phase.</li>
                <li><ExampleGreenFlag title='KLV'/>/<ExampleRedFlag title='KLV'/> = Aircraft positional data sent/received</li>
                <li><ExampleGreenFlag title='TTM'/>/<ExampleRedFlag title='TTM'/> = Radar tracks sent/received</li>
                <li><ExampleGreenFlag title='AIS'/>/<ExampleRedFlag title='AIS'/> = AIS data sent/received</li>
                <li><ExampleGreenFlag title='REC'/>/<ExampleRedFlag title='REC'/> =
                    Video Recording active/inactive within the aircraft (Aircraft side only)</li>
                <li><ExampleGreenFlag title='STR'/>/<ExampleRedFlag title='STR'/> =
                    Video Stream being received/not received on the IA terminal (IA side only)</li>
            </ul>
        </Typography>
    </Stack>
)


const ClientSectionHeader = ({children, clientName}) => {
    const [showUnassigned, setShowUnassigned] = useState(false)
    const {childMportals} = useConfigStore()
    const navigate = useNavigate()

    let show = true;
    if (clientName === 'unassigned' && !showUnassigned) {
        show = false
    }

    const showHide = useCallback(() => {
        if (clientName !== 'unassigned') return
        setShowUnassigned(!showUnassigned)
    }, [clientName, showUnassigned])

    const childMportal = useMemo(() => {
        return childMportals.find(c => c.client === clientName)?.domain
    }, [childMportals, clientName])

    const childMportalLink = childMportal
        ? <>
            <Typography sx={{display: 'inline-block', ml: 4, color:'#fa0'}}>
                dedicated mPortal:&nbsp;
            </Typography>
            <Link href={`https://${childMportal}`} target="_blank" sx={{lineHeight:'100%'}}>
                {childMportal}
            </Link>
        </>
        : '';

    const clickToShow = clientName === 'unassigned'
        ? <Link onClick={showHide}
                sx={{
                    ml:2,
                    display:'inline-block',
                    lineHeight:'100%',
                    cursor:'pointer'
                }}
        ><i>(click to {show?'hide':'show'})</i></Link>
        : '';

    const {canUser} = useLoginStore()

    const editLink = clientName !== 'unassigned' && canUser('GET', `/api/childmportal/${clientName}`)
        ? (
            <Link
                onClick={() => navigate(`/client/${clientName}`)}
                sx={{lineHeight: '100%', cursor: 'pointer'}}
            >Edit</Link>
        )
        : ''

    return <>
        <Grid item xs={12} sx={{
            p:2,
            mt:1,
            backgroundColor:'#222',
            borderRadius: '10px 10px 0 0',
            textSize:'1.2em',
            textAlign:'left'}}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box><b>{clientName}</b> {childMportalLink} {clickToShow}</Box>
                <Box>{editLink}</Box>
            </Stack>
        </Grid>
        {show ? children : ''}
    </>
}


const useIdentStore = create((set) => ({
    identity: '',
    setIdentity: (identity) => set({ identity }),
}))


export default function Home() {
    const successToast = useToastStore((state) => state.showSuccess)
    const {canUser} = useLoginStore()
    const {assignments} = useStatsStore()
    const [contract, setContract] = useState('')
    const {identity, setIdentity} = useIdentStore()
    const [loading, setLoading] = useState(false)
    const [assignedTo, setAssignedTo] = useState('')
    const [canAssign, setCanAssign] = useState(false)
    const [canSeeSESVideo, setCanSeeSESVideo] = useState(false)
    const [canEditConfigs, setCanEditConfigs] = useState(false)
    const is_gnd = identity.startsWith('IA-')
    const {primaryHost} = useConfigStore()

    const onDone = useCallback(() => successToast('Configuration saved'), [successToast])

    const assign = useApiCallback('POST', `/api/assign`, {setLoading, onDone})

    const assignClick = useCallback(() => {
        assign({identity, to: assignedTo, contract})
    }, [assign, assignedTo, contract, identity])

    useEffect(() => {
        assignments.forEach(row => {
            const [gnd, air, client, contract] = row
            if (gnd === identity || air === identity) {
                setContract((client ? client : '') + ' ' + (contract ? contract : ''))
                console.log('contract selected', client + ' ' + contract)
            }
        })
    }, [identity, setContract, assignments])

    const clients = useMemo(() => {
        /** Build cached list of clients. **/
        const _clients = []
        assignments.forEach(vars => {
            const [, , client, ] = vars
            if (_clients.indexOf(client) === -1) {
                _clients.push(client)
            }
        })
        _clients.sort()
        return _clients
    }, [assignments])

    const assignToList = useMemo(() => {
        const opts = []
        const is_gnd = identity.startsWith('IA-')
        assignments.forEach(vars => {
            const [gnd, air, , ] = vars
            const sel = is_gnd ? air : gnd
            if (sel)
                opts.push(sel)
            if (identity === gnd)
                setAssignedTo(air)
            if (identity === air)
                setAssignedTo(gnd)
        })
        opts.sort()
        console.log('ASSIGNED LIST', opts)
        return opts
    }, [identity, setAssignedTo, assignments])

    const [canSeeAllVideos, setCanSeeAllVideos] = useState(false)
    useEffect(() => {
        setCanAssign(!primaryHost && canUser('POST', '/api/assign'))
        setCanSeeSESVideo(canUser('GET', 'video/ses/live'))
        setCanEditConfigs(canUser('POST', '/api/config/'))
        setCanSeeAllVideos(canUser('GET', 'video/*'))
    }, [primaryHost, setCanAssign, setCanSeeSESVideo, setCanEditConfigs, setCanSeeAllVideos, canUser])

    const assignmentsByClientName = useMemo(() => {
        const gacc = {}
        clients.forEach(stepclient => {
            const clientName = stepclient || 'unassigned'
            gacc[clientName] = []
            assignments.forEach(vars => {
                const [gnd, air, client, ] = vars
                if (client === stepclient) {
                    gacc[clientName].push(gnd || air)
                }
            })
        })
        return gacc
    }, [clients, assignments])

    return (
        <Stack>
            { canSeeSESVideo
                ? <Box justifyContent="center" alignItems="center" sx={{
                        m: 2,
                        overflow: 'hidden',
                        position: 'relative',
                    }}>
                        <Video identity={'SES'} nested={true}/>
                  </Box>
                : ''
            }
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{padding:2}}>
                { canEditConfigs
                    ? <Button component={RLink} to={`/new`}>New</Button>
                    : ''
                }
                { canSeeAllVideos
                    ? <Button disabled={loading || !identity} component={RLink} to={`/video/${identity}`}>Video</Button>
                    : ''
                }
                <Button disabled={loading || !identity} component={RLink} to={`/stats/${identity}`}>Stats</Button>
                { canEditConfigs
                    ? <Button disabled={loading || !identity} component={RLink} to={`/config/${identity}`}>Config</Button>
                    : ''
                }
                <Button component={RLink} to={`/video/SES`}>SES</Button>
            </Stack>
            { canAssign
                ? <>
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{padding:2}}>
                        <ContractSelect disabled={loading || !identity} contract={contract} setContract={setContract}/>
                        <GenericSelect
                            setValue={setAssignedTo}
                            value={assignedTo}
                            label={is_gnd ? 'Assign Aircraft' : 'Assign Terminal'}
                            options={assignToList}
                            disabled={ loading || !identity}
                        />
                        <Button disabled={loading || !identity} onClick={assignClick}>Assign</Button>
                    </Stack>
                </>
                : ''
            }
            <NonPrimaryWarning/>
            <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
                fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
            { Object.keys(assignmentsByClientName).map(clientName => (
                <Grid item container xs={12} spacing={0} key={clientName}>
                    <ClientSectionHeader clientName={clientName}>
                        {assignmentsByClientName[clientName].map(gndorair =>
                            <StatusRow setSelected={setIdentity} identity={gndorair} selected={identity}/>
                        )}
                    </ClientSectionHeader>
                </Grid>
            )) }
            </Grid>
            { assignedTo?.length
                ? <Legend/>
                : ''
            }
        </Stack>
    )
}
