import Stack from "@mui/material/Stack"
import React, {useState, useEffect, useCallback, useMemo} from "react";
import Typography from "@mui/material/Typography";
import {useApiCallback} from "../utils/Api";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {timeSince} from "../utils/time";
import {useLoginStore} from "../Login";
import {useServerStore} from "../utils/useServerStore";

export default function Watchers({filter=''}) {
    const {servertime} = useServerStore()
    const [loading, setLoading] = useState(false)
    const {canUser} = useLoginStore()
    const [watchers, setWatchers] = useState([])
    const doGetWatchers = useApiCallback('GET', `/api/streams/watchers/${filter}`, {setLoading, onDone: setWatchers})

    const [timer, setTimer] = useState(0)
    useEffect(() => {
        const tt = setTimeout(() => setTimer(t => t + 1), 5_000)
        return () => clearTimeout(tt)
    }, [timer, setTimer])

    useEffect(() => {
        if (canUser('GET', '/api/streams/watchers')) {
            doGetWatchers()
        } else {
            setWatchers([])
        }
    }, [setWatchers, canUser, doGetWatchers, timer])

    const tableData = useMemo(() => (
        watchers.map(w => ({
            'id': w.id,
            'stream': w.app + '/' + w.stream,
            'bytes': w.bytes,
            'first_seen': w.first_seen,
            'geo_country': w.geo_country,
            'geo_isp': w.geo_isp,
            'ip': w.ip,
            'last_seen': w.last_seen,
            'platform': w.platform,
            'user_agent': w.user_agent,
        }))
    ), [watchers])

    const renderTimeSince = useCallback(params => timeSince(servertime, params.value), [servertime])

    const columnDefinition = useMemo(() => {
        return [
            {field: 'stream', headerName: 'Stream', flex: 1},
            {field: 'ip', headerName: 'IP', flex: 1},
            {field: 'geo_country', headerName: 'Country', flex: 1},
            {field: 'geo_isp', headerName: 'ISP', flex: 1},
            {field: 'first_seen', headerName: 'First Seen', flex: 1, renderCell: renderTimeSince},
            {field: 'last_seen', headerName: 'Last Seen', flex: 1, renderCell: renderTimeSince},
            {field: 'user_agent', headerName: 'User Agent', flex: 1},
            {field: 'platform', headerName: 'Platform', flex: 1},
            {field: 'bytes', headerName: 'Bytes', flex: 1},
        ]
    }, [renderTimeSince])

    return <Stack sx={{width:'100%'}}>
        <Typography component="h2" sx={{textAlign:'center', fontSize:'1.4em'}}>
            Watchers
        </Typography>
        <div style={{height: 'calc(100vh - 180px)', width: '100%'}}>
            <DataGridPro
                hideFooter={true}
                loading={loading}
                rows={tableData}
                disableMultipleRowSelection={true}
                columns={columnDefinition}
            />
        </div>
    </Stack>
}
