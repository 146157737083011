/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import AppsRoundedIcon from '@mui/icons-material/Apps';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Menu from '@mui/material/Menu'
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import GridMenuItem from './GridMenuItem';
import {useState, useRef, useCallback} from "react";


const WaffleMenu = ({menuItems, onToggle}) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const anchorElement = useRef();

    const handleToggle = useCallback(event => {
        setOpen(!open);
        onToggle && onToggle(!open);
    }, [onToggle, open]);

    const handleMenuItemClick = useCallback(item => {
        window.open(item.link, '_blank', 'noopener,noreferrer');
    }, []);

    const _theme = theme.palette.mode;
    const getIcon = useCallback((item) => (
        <img src={_theme === 'dark' ? item.icon : item?.icon_dark || item.icon}
             alt={item.label}
             className="grid-menu-item-icon"
        />
    ), [_theme])

    return <>
        <IconButton onClick={handleToggle} ref={anchorElement} sx={{color: theme.palette.primary.main}}>
            {open ? <ExpandMoreRoundedIcon/> : <AppsRoundedIcon/>}
        </IconButton>
        <Menu
            id="menu-appmenu"
            anchorEl={() => anchorElement.current}
            open={open}
            onClose={handleToggle}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '24px',
                    padding: '10px',
                    backgroundColor: theme.palette.background.default,
                    backgroundImage: 'none',
                    borderWidth: '10px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.background.paper
                },
                '& .MuiList-root': {
                    backgroundColor: theme.palette.background.default,
                    paddingLeft: "8px",
                    paddingRight: "8px",
                }
            }}
        >
            <Grid container columns={3} spacing={2} sx={{width: "300px"}}>
                {menuItems.map((item) => (
                    <Grid item key={item.label} xs={1} onClick={() => handleMenuItemClick(item)}>
                        <GridMenuItem icon={getIcon(item)} label={item.label}/>
                    </Grid>
                ))}
            </Grid>
        </Menu>
    </>
};

export default WaffleMenu;
