import {create} from "zustand";

const useConfigStore = create((set) => ({
    configs: {},
    ipmap: {},
    streams: {},
    primaryHost: null,
    childMportals: [],  //[{'client': 'DEA', 'domain': 'dev.isr.dea.aero/mportal'}],
    setupLogs: {},
    setConfigs: (configs) => set({ configs }),
    setIpMap: (ipmap) => set({ ipmap }),
    setStreams: (streams) => set({ streams }),
    setPrimaryHost: (primaryHost) => set({ primaryHost }),
    setChildMportals: (childMportals) => set({ childMportals }),
    clearSetupLogs: (identity) => set((state) => ({
        setupLogs: {
            ...state.setupLogs,
            [identity]: [],
        },
    })),
    addSetupLogs: (identity, logs) => set((state) => ({
        setupLogs: {
            ...state.setupLogs,
            [identity]: [...state.setupLogs[identity], ...logs],
        },
    })),
}))
export default useConfigStore
