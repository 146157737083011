/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Stack from "@mui/material/Stack"
import {useLoginStore} from "../Login";
import {Chip} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GenericSelect from "../component/GenericSelect";
import {useEffect, useState} from "react";
import {useGlobalAppTheme} from "../utils/useGlobalAppTheme";
import TextField from "@mui/material/TextField";

export default function Modems() {

    const assigned = [
        {freq:1031.255, bw: 800,  type:'fwd', identity:'G-WKTH', ip:'10', color: 'orange'},
        {freq:1058.093, bw: 1000, type: 'rtn', identity:'G-WKTH', ip:'12', color: '#ddd'},

        {freq:1029.301, bw: 800,  type:'fwd', identity:'G-WKTI', ip:'20', color: 'orange'},
        {freq:1061.523, bw: 1000, type: 'rtn', identity:'G-WKTI', ip:'22', color: '#ddd'},

        {freq:1023.0,   bw: 800,  type:'fwd', identity:'2-WKTJ', ip:'30', color: 'orange'},
        {freq:1074.8,   bw: 1700, type: 'rtn', identity:'2-WKTJ', ip:'32', color: '#ddd'},

        {freq:1033.440, bw: 800,  type:'fwd', identity:'2-WKTN', ip:'40', color: 'orange'},
        {freq:1053.020, bw: 1300, type: 'rtn', identity:'2-WKTN', ip:'42', color: '#ddd'},

        {freq:1024.8,   bw: 800,  type:'fwd', identity:'9H-DGM', ip:'50', color: 'orange'},
        {freq:1072.6,   bw: 1000, type: 'rtn', identity:'9H-DGM', ip:'52', color: '#ddd'},

        {freq:1027.440, bw: 800, type:'fwd', identity:'9H-DGN', ip:'60', color: 'orange'},
        {freq:1065.633, bw: 1000, type: 'rtn', identity:'9H-DGN', ip:'62', color: '#ddd'},

        {freq:1035.625, bw: 800,  type:'fwd', identity:'2-WKTL', ip: '70', color: 'orange'},
        {freq:1070.2,   bw: 1400, type: 'rtn', identity:'2-WKTL', ip: '72', color: '#ddd'},

        {freq:1044, bw: 800,  type:'fwd', identity:'9H-DGB', ip: '90', color: 'orange'},
        {freq:1063.6, bw: 1000, type: 'rtn', identity:'9H-DGB', ip:'92', color: '#ddd'},

        {freq:1047, bw: 800,  type:'fwd', identity:'G-WKTS', ip: '100', color: 'orange'},
        {freq:1067.8, bw: 1400, type: 'rtn', identity:'G-WKTS', ip:'102', color: '#ddd'},

        {freq:1049.0, bw: 400,  type:'fwd', identity:'G-WKTK', ip: '110', color: 'orange'},
        {freq:1059.8, bw: 1300, type: 'rtn', identity:'G-WKTK', ip:'112', color: '#ddd'},

        {freq:1040.499, bw: 128,  type: 'fwd', identity:'TRACK', ip:'80', rolloff:.8, color: 'red'},
    ];

    return (
        <Stack>
            <Box mt={3}>
                <TextField>{JSON.stringify(assigned, null, 2)}</TextField>
            </Box>
        </Stack>
    )
}
