import {useCallback, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import {Login} from "../Login";
import {useApiCallback} from "../utils/Api";
import {useToastStore} from "../Toast";
import TotpInput from "../component/TotpInput";
import {PWLOGIN} from "../AppConfigStore";

export default function LoginForm() {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [totp, setTotp] = useState('')
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const [mfaRequired, setMfaRequired] = useState(false)
    const {showError, showSuccess, showInfo} = useToastStore()
    const [newPassword, setNewPassword] = useState('')

    const navigate = useNavigate()

    const checkLogin = useApiCallback('GET', '/api/login', {
        setLoading,
        onDone: setChecked,
    })
    useEffect(checkLogin, [checkLogin])

    const handleLoginResponse = useCallback(resp => {
        console.log('RESPO', resp)
        if (resp.status === 'ok') {
            showSuccess('Authentication successful')
            setNewPassword('')
            setPassword('')
            setUsername('')
            navigate(0)
        } else if (resp.data === 'mfa required') {
            setMfaRequired(true)
            setNewPassword('')
            showInfo('Multi-factor auth code is required')
        } else if (resp.data.startsWith('password reset:')) {
            setNewPassword(resp.data.replace('password reset: ', ''))
        } else {
            showError(`${resp.status}: ${resp.data}`)
            setNewPassword('')
        }
    }, [navigate, showError, showInfo, showSuccess, setNewPassword])

    const doLogin = useApiCallback('POST', `/api/login`, {
        data: {username, password, totp},
        setLoading,
        onDone: handleLoginResponse,
        returnAll: true,
        ignoreErrors: true,
    })

    if (checked === false || checked?.data?.active) {
        // if user logged in state hasnt been checked yet, or if the user is logged in, dont show the login form
        return ''
    }

    if (mfaRequired) {
        window.addEventListener('message', (event) => {
            if (event.data.action === 'totpResponse') {
                setTotp(event.data.totpCode)
            }
        });

        window.postMessage({
            action: 'requestTOTP',
            accountName: 'DEA DataHub'
        }, '*');
    }

    if (!PWLOGIN) {
        return <Container>
            <form onSubmit={() => false}>
                Please Login
                <br/>
                <br/>
                <Login/>
            </form>
        </Container>
    }

    return <Container><form onSubmit={() => false}>
        Please Login
        <br/>
        <br/>
        <Login/>
        <br/>
        <br/>
        <i>- or -</i>
        <br/>
        <br/>
        <TextField
            label="Username/Email"
            disabled={loading}
            InputLabelProps={{shrink: true}}
            value={username}
            inputProps={{autocomplete:"username"}}
            onChange={(event) => {
                setUsername(event.target.value)
            }}
            sx={{width: '300px', margin: 2}}
        />
        <br/>
        <TextField
            label="Password"
            disabled={loading}
            InputLabelProps={{shrink: true}}
            value={password}
            type="password"
            inputProps={{autocomplete:"current-password"}}
            onChange={(event) => {
                setPassword(event.target.value)
            }}
            sx={{width: '300px', margin: 2}}
        />
        <br/>
        {mfaRequired
            ? (
                <TotpInput
                    label="Multi Factor Authentication"
                    disabled={loading}
                    value={totp}
                    setValue={setTotp}
                />
            )
            : ''
        }
        <br/>

        { /* @TODO add forced password reset engagement */ }
        {newPassword
            ? (
                <Stack my={4}>
                    <Typography>
                        Your new password is below.
                    </Typography>
                    <code style={{display:'inline-block', border: '1px solid red', borderRadius:10}}>{newPassword}</code>
                    <Typography>
                        Please record this password somewhere safe,
                        then copy and paste it into the password box above to login.
                    </Typography>
                </Stack>
            )
            : ''
        }

        <Button
            disabled={loading}
            type="submit"
            onClick={() => doLogin()}
        >Login</Button>
    </form></Container>
}
