import React, {useMemo} from "react";
import useConfigStore from "../utils/useConfigStore";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {useLocation} from "react-router-dom";
import useStatsStore from "../utils/useStatsStore";
import Stack from "@mui/material/Stack";


export function DelegatedMportalWarning({children, identity}) {
    const {childMportals} = useConfigStore()
    const {assignments} = useStatsStore()
    const loc = useLocation()

    const clientName = useMemo(() => {
        const ass = assignments.find(a => a[0] === identity || a[1] === identity)
        return ass ? ass[2] : null
    }, [assignments, identity])

    const childMportal = useMemo(() => {
        return childMportals.find(c => c.client === clientName)?.domain
    }, [childMportals, clientName])

    if (!childMportal) return <></>

    return <Stack
        xs={12}
        style={{
            border: '2px dotted #fa0',
            padding: '20px',
            borderRadius: '20px',
            margin: '40px auto',
            minHeight: '100px',
            height: '100%',
            maxHeight: '400px',
            width: '80%',
            textAlign: 'center',
        }}
        container
        justifyContent="center"
        alignItems="center"
    >
        <Grid item xs={12}>
            {children}
        </Grid>
        <Grid item xs={12}>
            <Link href={`https://${childMportal}/#{loc.pathname}`} target="_blank" sx={{lineHeight: '100%'}}>
                {childMportal}/#{loc.pathname}
            </Link>
        </Grid>
    </Stack>
}


export default function NonPrimaryWarning() {
    const {primaryHost} = useConfigStore()
    const loc = useLocation()
    if (!primaryHost) return ''
    return (
        <Stack
            xs={12}
            style={{
                border: '2px dotted #fa0',
                padding: '20px',
                borderRadius: '20px',
                margin: '40px auto',
                minHeight: '100px',
                height: '100%',
                width: '80%',
                textAlign: 'center',
            }}
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                Some features only available on the primary mPortal.
            </Grid>
            <Grid item xs={12}>
                Please go here:&nbsp;
            </Grid>
            <Grid item xs={12}>
                <Link href={`https://${primaryHost}/#${loc.pathname}`} target="_blank">
                    https://{primaryHost}/#{loc.pathname}
                </Link>
            </Grid>
        </Stack>
    )
}
