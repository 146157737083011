import React, {useCallback, useEffect, useRef, useState} from "react"
import TextField from "@mui/material/TextField";
import {useToastStore} from "../Toast";
import {useApiCallback} from "../utils/Api";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";

const BASECONF = `[
    {"freq": 1031.255, "bw": 800, "power": 20, "name": "G-WKTH", "ip": 10, "color": "orange"},
    {"freq": 1058.093, "bw": 1000, "power": 10, "name": "G-WKTH", "ip": 12, "color": "#ddd"},

    {"freq": 1029.301, "bw": 800, "power": 20, "name": "G-WKTI", "ip": 20, "color": "orange"},
    {"freq": 1061.523, "bw": 1000, "power": 10, "name": "G-WKTI", "ip": 22, "color": "#ddd"},

    {"freq": 1023.000, "bw": 800, "power": 20, "name": "2-WKTJ", "ip": 30, "color": "orange"},
    {"freq": 1074.800, "bw": 1700, "power": 10, "name": "2-WKTJ", "ip": 32, "color": "#ddd"},

    {"freq": 1033.440, "bw": 800, "power": 20, "name": "9H-DGB", "ip": 40, "color": "orange"},
    {"freq": 1053.020, "bw": 1300, "power": 10, "name": "9H-DGB", "ip": 42, "color": "#ddd"},

    {"freq": 1024.800, "bw": 800, "power": 20, "name": "9H-DGM", "ip": 50, "color": "orange"},
    {"freq": 1072.600, "bw": 1000, "power": 10, "name": "9H-DGM", "ip": 52, "color": "#ddd"},

    {"freq": 1027.440, "bw": 800, "power": 20, "name": "9H-DGN", "ip": 60, "color": "orange"},
    {"freq": 1065.633, "bw": 1000, "power": 10, "name": "9H-DGN", "ip": 62, "color": "#ddd"},

    {"freq": 1035.625, "bw": 800, "power": 20, "name": "2-WKTL", "ip": 70, "color": "orange"},
    {"freq": 1070.200, "bw": 1400, "power": 10, "name": "2-WKTL", "ip": 72, "color": "#ddd"},

    {"freq": 1038.000, "bw": 800, "power": 20, "name": "G-DMNG", "ip": 120, "color": "orange"},
    {"freq": 1056.000, "bw": 1000, "power": 10, "name": "G-DMNG", "ip": 122, "color": "#ddd"},

    {"freq": 1040.499, "bw": 128, "power": 22, "name": "TRACK", "ip": 80, "rolloff": 100, "color": "red"},

    {"freq": 1044.000, "bw": 800, "power": 20, "name": "2-WKTK", "ip": 90, "color": "orange"},
    {"freq": 1063.600, "bw": 1000, "power": 10, "name": "2-WKTK", "ip": 92, "color": "#ddd"},

    {"freq": 1047.000, "bw": 800, "power": 20, "name": "G-WKTS", "ip": 100, "color": "orange"},
    {"freq": 1067.800, "bw": 1400, "power": 10, "name": "G-WKTS", "ip": 102, "color": "#ddd"},

    {"freq": 1049.000, "bw": 400, "power": 20, "name": "2-WKTN", "ip": 110, "color": "orange"},
    {"freq": 1059.800, "bw": 1300, "power": 10, "name": "2-WKTN", "ip": 112, "color": "#ddd"}
]`

export default function Spectrum() {

    const ref = useRef()
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    const [conf, setConf] = useState(BASECONF)
    const errorToast = useToastStore((state) => state.showError)
    const onDone = useCallback(data => {
        data.forEach(row => row.freq /= 1000)
        return setConf(JSON.stringify(data, null, 2))
    }, [])
    const getSpectrum = useApiCallback('GET', '/api/spectrum', {onDone, setLoading})
    useEffect(getSpectrum, [getSpectrum])

    const setSpectrum = useApiCallback('POST', '/api/spectrum', {setLoading})

    const saveClick = useCallback(() => {
        try {
            const dd = JSON.parse(conf)
            dd.forEach(row => row.freq *= 1000)
            setSpectrum(dd)
        } catch (e) {
            errorToast(String(e))
        }
    }, [conf, errorToast, setSpectrum])

    const draw = useCallback(() => {
        if (!ref.current) return
        console.log('Drawing canvas')
        const canvas = ref.current;
        let _v = conf
        try {
            _v = JSON.parse(_v)
        } catch (e) {
            errorToast(String(e))
            setValid(false)
            return
        }
        setValid(true)
        const vals = _v

        const cx = canvas.getContext("2d");
        cx.fillStyle = "#000";
        cx.clearRect(0, 0, 999999, 9999999)
        if (!cx?.scaled) {
            console.log('cx.width', cx.width)
            cx.scaled = true
            cx.scale(0.25, 0.25)
        }
        for (let v in vals) {
            const carrier = vals[v];
            cx.lineWidth = 8;

            const center = (carrier.freq - 1022) * 100;
            const width = (carrier.bw * 0.001) * 100;
            const left = center - (width / 2);
            const right = left + width;
            const top = 1400 - (carrier.power * 40);
            const rolloff = (carrier.rolloff || 10) / 100;

            // TOP IP ADDR
            cx.fillStyle = carrier.color || '#fa0';
            cx.font = '80px Arial';
            cx.textAlign = 'center';
            cx.fontWeight = 'bold';
            cx.fillText(carrier.ip, center, top + 200);

            // TOP TEXT
            cx.save();
            cx.translate(center + 15, top + 50);
            cx.rotate(-Math.PI / 2);
            cx.textAlign = 'left';
            cx.fillText(carrier.name, 0, 22 / 2);
            cx.restore();

            // SHADED COLUMN
            cx.save();
            cx.beginPath();
            cx.fillStyle = '#ffaa0026';
            cx.moveTo(left - (width * rolloff), 0);
            cx.lineTo(left - (width * rolloff), 3000);
            cx.lineTo(right + (width * rolloff), 3000);
            cx.lineTo(right + (width * rolloff), 0);
            cx.fill();
            cx.restore();

            const ltop = 620

            {
                // BOTTOM TEXT
                cx.save();
                cx.fillStyle = carrier.color || '#fa0';
                const center = (carrier.freq - 1021.92) * 100;
                cx.translate(center, ltop + 1250);
                cx.rotate(-Math.PI / 2.0);
                cx.textAlign = 'right';
                const text = (carrier.freq.toFixed(0)) + '  ' + ((carrier.freq + 12800).toFixed(0)) + '  Mhz'
                cx.fillText(text, 0, 22 / 2);
                cx.restore();
            }
        }
        cx.save()
    }, [conf, errorToast]);

    useEffect(() => {
        const x = setTimeout(draw, 0)
        return () => clearTimeout(x)
    }, [draw]);

    return <>
        <canvas ref={ref} width="1352px" height='700px' style={{width: '100%', height: '500px', border: '1px solid #fa0'}}/>
        <Box style={{height: 'calc(100vh - 580px)', width: '100%', overflowY:'auto'}}>
            <TextField
                label="Config"
                InputLabelProps={{shrink: true}}
                multiline
                disabled={loading}
                value={conf}

                onChange={(event) => {
                    setConf(event.target.value)
                }}
                InputProps={{
                    style: {fontFamily: 'Monospace', fontSize: 12, fontWeight: 'bold'},
                }}
                sx={{width: '100%', marginTop:3, backgroundColor: '#333'}}
            />
            <Button disabled={loading || !valid} onClick={saveClick}>Save</Button>
        </Box>
    </>
}
