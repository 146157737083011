/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import {Chip, Link} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";

import {useLoginStore} from "../Login";
import GenericSelect from "../component/GenericSelect";
import {useGlobalAppTheme} from "../utils/useGlobalAppTheme";
import {useApiCallback, useApiStore} from "../utils/Api";
import BaseModal from "../component/BaseModal";

export function AuthorityChip({authority}) {
    const props = {
        sx: {width:70, m:'0.5px', mr:2},
        label: authority,
        size: 'small',
    }
    if (authority === 'owner') {
        props.color = "success"
    }
    else if (authority === 'manager') {
        props.variant = "outlined"
        props.color = "success"
    }
    return <Chip {...props}/>
}

const GroupInfoLink = ({group, navigate}) => (
    <Link color="inherit"
          sx={{lineHeight: '160%', cursor: 'pointer'}}
          onClick={() => navigate(`/group/${group}`)}
    >{group}</Link>
)

export default function Preferences() {
    const {groups, id} = useLoginStore()

    const {globalAppTheme, setGlobalAppTheme} = useGlobalAppTheme()
    const [applicationTheme, setApplicationTheme] = useState(globalAppTheme)
    const {asUser, setAsUser} = useApiStore()
    const {canUser} = useLoginStore()
    const userIsAdmin = canUser('POST', '/api/acl')
    const navigate = useNavigate()

    const [newAsUser, setNewAsUser] = useState('')
    useEffect(() => {
        const x = setTimeout(() => setAsUser(newAsUser), 1000)
        return () => clearTimeout(x)
    }, [newAsUser, setAsUser])

    useEffect(() => {
        console.log("Application theme was updated to " + applicationTheme)
        setGlobalAppTheme(applicationTheme)
    }, [setGlobalAppTheme, applicationTheme])

    const [prefs, setPrefs] = useState(localStorage.length)
    const clearPrefs = useCallback(() => {
        localStorage.clear()
        setPrefs(0)
    }, [])

    const themeOptions = [
        {code: 'system', label: "Use System Theme"},
        {code: 'light', label: "Light"},
        {code: 'dark', label: "Dark"}
    ]


    const [loading, setLoading] = useState(false)
    const [resetDone, setResetDone] = useState({})
    const resetPassword = useApiCallback('POST', `/api/user/passwordreset`, {setLoading, onDone: setResetDone})
    const newpassword = resetDone?.newpassword
    const resetModal = useMemo(() => {
        if (!newpassword) return ''
        return <BaseModal key={Math.random()} title="New Password" clickSave={() => setResetDone(null)}>
            <pre>{newpassword}</pre>
            <Typography my={2}>
                Use the field below to save the password into your password manager.
            </Typography>
            <TextField
                type="text"
                value={id}
                sx={{display:'none'}}
                inputProps={{autocomplete:"username"}}
            />
            <TextField
                type="password"
                id="new-password"
                label="New Password"
                InputLabelProps={{shrink: true}}
                value={newpassword}
                inputProps={{autocomplete:"new-password"}}
            />
        </BaseModal>
    }, [id, newpassword])

    return <Container><Grid container>
        {resetModal}
        <Grid xs={12} md={6} item>
            <Typography variant="h5">My Groups</Typography>
            <em>Your permissions within this system are derived from these memberships.</em>
            <Box mt={3}>
                {Object.keys(groups).map(groupemail => (
                    <Box sx={{display: 'flex'}} key={groupemail}>
                        <AuthorityChip authority={groups[groupemail]}/>
                        {canUser('GET', `/api/group/members/${groupemail}`)
                            ? <GroupInfoLink group={groupemail} navigate={navigate}/>
                            : groupemail
                        }
                    </Box>
                ))}
            </Box>
        </Grid>
        <Grid xs={12} md={6} item>
            <Typography variant="h5" mt={3}>Colour Theme</Typography>
            <GenericSelect
                hideBlankOption={true}
                sx={{maxWidth: 200}}
                setValue={setApplicationTheme}
                value={applicationTheme}
                label="Application Theme"
                options={themeOptions}
                disableClearable={true}
            />

            {userIsAdmin || asUser ? <>
                <Typography variant="h5" mt={3}>AsUser</Typography>
                <Stack direction="row">
                    <GenericSelect
                        setValue={setNewAsUser}
                        sx={{width: 240}}
                        custom={true}
                        value={asUser}
                        label="AsUser"
                        options={[]}
                    />
                    <Button onClick={() => setAsUser('')}>Clear AsUser</Button>
                </Stack>
            </> : ''}

            <Typography variant="h5" mt={3}>Clear Preferences</Typography>
            <Button onClick={clearPrefs} disabled={!prefs} color="warning">Clear {prefs} items</Button>

            <Typography variant="h5" mt={3}>Account Security</Typography>
            <Button onClick={() => navigate('/mfa')} color="success">multi-factor auth</Button>
            <Button disabled={loading} color="warning" onClick={() => resetPassword()}>Reset Password</Button>
        </Grid>
    </Grid></Container>
}
