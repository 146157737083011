import {useEffect, useState} from "react";
import {timeSinceInt} from "../utils/time";
import useStatsStore from "../utils/useStatsStore"
import Chip from '@mui/material/Chip';
import Ago from "./Ago";
import Typography from "@mui/material/Typography";
import {useServerStore} from "../utils/useServerStore";

const titles = {
    KLV: 'Aircraft positional data and camera footprint',
    TTMs: 'Radar tracked targets',
    TTM: 'Radar tracked targets',
    AIS: 'Marine vessels',
    STR: 'Streaming video',
    REC: 'Recording video',
}

const chipStyle = {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: 0,
    margin: '0 1px',
    borderRadius: '4px',
    fontSize: '0.75em',
    height: 'auto',
    border: 0,
    '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
}

const LABEL_SHOW_THRESHOLD = 40

const redpill = {
    backgroundColor: '#960000',
    color: '#f59595',
}
const greenpill ={
    backgroundColor: '#00ab00',
    color: '#000',
}
const yellowpill ={
    backgroundColor: '#acac00',
    color: '#000',
}
const orangepill = {
    backgroundColor: '#b47700',
    color: '#000',
}


export const ExampleRedFlag = ({title='no data'}) => {
    return <Chip size="small" sx={{...chipStyle, ...redpill}} label={title} title={title}/>
}


export const ExampleGreenFlag = ({title='some data'}) => {
    return <Chip size="small" sx={{...chipStyle, ...greenpill}} label={title} title={title}/>
}


const VideocryptFlag = ({identity}) => {
    const statsStore = useStatsStore()
    const {servertime} = useServerStore()

    if (statsStore.lastseen[identity] < servertime - LABEL_SHOW_THRESHOLD)
        return <></>

    try {
        if (!statsStore.stats.hasOwnProperty(identity)) return <></>
        const {videocrypt = null} = statsStore.stats[identity]
        if (videocrypt === null) return <></>
        const {data = {}} = videocrypt
        let colour = redpill
        Object.values(data).forEach(v => {
            if (v.match(/🟢 bitrate:/)) {
                colour = greenpill
            }
        })
        const title = 'REC'
        return <Chip size="small" sx={{...chipStyle, ...colour}} label={title} title={titles[title]}/>
    } catch (e) {
        console.error(e)
    }
    return <></>
}

const SRTSplitterFlag = ({identity}) => {
    const statsStore = useStatsStore()
    const {servertime} = useServerStore()

    if (statsStore.lastseen[identity] < servertime - LABEL_SHOW_THRESHOLD)
        return <></>

    try {
        let reds = 0
        let greens = 0
        if (!statsStore.stats.hasOwnProperty(identity)) return <></>
        const {srtsplitter = {}} = statsStore.stats[identity]
        const {data = {}} = srtsplitter
        Object.values(data).forEach(v => {
            if (v.match(/🔴/)) {
                reds++ // red circle
            }
            if (v.match(/🟢/)) {
                greens++ // green circle
            }
        })
        if (reds || greens) {
            let colour = redpill
            if (reds) {

            } else if (greens) {
                colour = greenpill
            }
            const title = 'STR'
            return <Chip size="small" sx={{...chipStyle, ...colour}} label={title} title={titles[title]}/>
        }
    } catch (e) {
        //console.error(e);
    }
    return <></>
}


const UDPCryptorFlag = ({identity}) => {
    const statsStore = useStatsStore()
    const {servertime} = useServerStore()

    if (statsStore.lastseen[identity] < servertime - LABEL_SHOW_THRESHOLD)
        return <></>

    const labels = []
    try {
        if (!statsStore.stats.hasOwnProperty(identity)) return []
        const {udpcryptor = {}} = statsStore.stats[identity]
        const {data = []} = udpcryptor
        if (!data.length) {
            data.push('🔴 INIT')
        }
        data.forEach(v => {
            const [, icon = null, title = null] = v.match(/(🔴|🟠|🟡|🟢|⚪|⚫|) ([^ ]+)/)
            let colour
            switch (icon) {
                case '🔴':  //red circle
                    colour = redpill
                    break
                case '🟢':  // green circle
                    colour = greenpill
                    break
                case '🟡':  // yellow circle
                    colour = yellowpill
                    break
                case '🟠':  // orange circle
                    colour = orangepill
                    break
                default:
                    colour = redpill
            }
            labels.push(
                <Chip size="small" key={title} sx={{...chipStyle, ...colour}} label={title} title={titles[title]}/>
            )
        })
    } catch (e) {
        console.error('errr', identity, e);
    }
    return labels
}

const TimeSince = ({identity}) => {
    const {lastseen} = useStatsStore()
    return <Ago at={lastseen[identity]} suffix=''/>
}

export default function Flags({identity}) {
    const statsStore = useStatsStore()
    const [flags, setFlags] = useState(<>-</>)
    const [showFlags, setShowFlags] = useState(false)
    const [ts, setTs] = useState(<></>)
    const [beat, setBeat] = useState('')
    const {servertime} = useServerStore()

    const lastseen = statsStore.lastseen[identity]

    useEffect(() => {
        if (!identity){
            setTs('∞')
            return
        }
        setTs(<TimeSince identity={identity}/>)
    }, [identity, setTs])

    useEffect(() => {
        setBeat('beat')
        const tt = setTimeout(() => {
            setBeat('beat beat-on')
        }, 10)
        return () => clearTimeout(tt)
    }, [setBeat, lastseen])

    useEffect(() => {
        /** Build the KLV, REC, STR etc flags for top grid display. **/
        if (!showFlags) {
            return
        }
        const rec = <VideocryptFlag key={'rec'} identity={identity}/>
        const srt = <SRTSplitterFlag key={'srt'} identity={identity}/>
        const udp = <UDPCryptorFlag key={'udp'} identity={identity}/>
        setFlags(<Typography variant="div" className={beat} style={{borderRadius: '5px'}}>
            {rec}
            {srt}
            {udp}
        </Typography>)
    }, [beat, identity, setFlags, showFlags])

    useEffect(() => {
        const since = timeSinceInt(servertime, lastseen)
        if (since > LABEL_SHOW_THRESHOLD) {
            setShowFlags(false)
        } else {
            setShowFlags(true)
        }
    }, [setShowFlags, servertime, lastseen])

    if (!lastseen)
        return '∞'

    return showFlags ? flags : ts
}
