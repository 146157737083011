/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Stack from "@mui/material/Stack"
import {useLoginStore} from "../Login";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useApiCallback, useApiStore} from "../utils/Api";
import {useEffect, useMemo, useState} from "react";
import {AuthorityChip} from "./Preferences";
import {useNavigate, useParams} from "react-router-dom";
import {Link} from "@mui/material";
import PersonAvatar from "../component/PersonAvatar";
import Button from "@mui/material/Button";

const GroupInfoLink = ({group, navigate}) => (
    <Link color="inherit"
          sx={{lineHeight: '160%', cursor: 'pointer'}}
          onClick={() => navigate(`/group/${group}`)}
    >{group}</Link>
)

export default function PersonInfo() {
    const {email} = useParams()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {setAsUser} = useApiStore()
    const {canUser} = useLoginStore()
    const userIsAdmin = canUser('POST', '/api/acl')
    const canUserSeeGroups = canUser('GET', `/api/person/groups/${email}`)

    const [groups, setGroups] = useState({});
    const getGroups = useApiCallback('GET', `/api/person/groups/${email}`, {setLoading, onDone: setGroups})
    useEffect(() => {
        setGroups({})
        if (canUserSeeGroups) getGroups()
    }, [canUserSeeGroups, getGroups]);

    const sorted = useMemo(() => {
        const _ord = ['owner', 'manager', 'member']
        const arr = Object.keys(groups)
        arr.sort(
            (a, b) => groups[a] === groups[b] ? a - b : _ord.indexOf(groups[a]) - _ord.indexOf(groups[b])
        )
        return arr
    }, [groups])

    const asUser = useMemo(() => {
        if (!userIsAdmin) return ''
        return <>
            <Typography variant="h5" mt={3}>AsUser</Typography>
            <Button color="error" onClick={() => setAsUser(email)}>Admin: Become User</Button>
        </>
    }, [email, setAsUser, userIsAdmin])

    return <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
        <Box container>
            <Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
                <PersonAvatar person={email} sx={{height:100, width:100}}/>
                <Typography variant="h5"><em>{email}</em></Typography>
            </Stack>
            <Box mt={3}>
            {loading ? 'loading…' : ''}
            {sorted.map(group => (
                <Box sx={{display: 'flex'}} key={group}>
                    <AuthorityChip authority={groups[group]}/>
                    <GroupInfoLink group={group} navigate={navigate}/>
                </Box>
            ))}
            </Box>
            {asUser}
        </Box>
    </Stack>
}
