/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Grid from '@mui/material/Grid'
import {Button} from '@mui/material'
import {ErrorBoundary} from "react-error-boundary";
import {useCallback} from "react";

export default function ErrorBlock(params) {
    const {name, children} = params

    const Fallback = useCallback(({error, resetErrorBoundary}) => {
        console.log(error)
        return <Grid
            xs={12}
            style={{
                border: '2px dotted red',
                padding: '20px',
                borderRadius: '20px',
                margin: '40px auto',
                minHeight: '100px',
                height: '100%',
                width: '80%',
                zIndex: 100,
                backgroundColor: '#000',
            }}
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                {name} is broken.
            </Grid>
            <Grid item xs={12}>
                <pre style={{color: "red", whiteSpace:"pre-wrap"}}>
                    {error.message}
                    {error.stack}
                </pre>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={resetErrorBoundary}>Retry</Button>
            </Grid>
        </Grid>
    }, [name])

    return <ErrorBoundary FallbackComponent={Fallback}>
        {children}
    </ErrorBoundary>
}
