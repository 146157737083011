/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React, {useState, useEffect, useMemo, useCallback} from "react";
import TextField from "@mui/material/TextField";
import {Button, Table, TableCell, TableRow} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {useAppConfigStore} from "../AppConfigStore";
import {useApiCallback} from "../utils/Api";
import {useLoginStore} from "../Login";
import {useToastStore} from "../Toast";


const buttonStyle = {
    textTransform: 'none',
    margin: '1px',
}

const ConfigValue = ({value, onClick}) =>
    <Typography
        component="code"
        variant="code"
        onClick={onClick}
        sx={{cursor: 'pointer', display:'inline-block', minWidth:'100px', px: 2, backgroundColor:'#7777'}}
    >{value || '_empty_'}</Typography>


export default function Admins() {
    const [loading, setLoading] = useState(false)
    const {canUser} = useLoginStore()

    const [config, setConfig] = useState({})
    const fetchConfig = useApiCallback('POST', `/api/config/fetch`, {setLoading, onDone: setConfig})
    const getConfig = useApiCallback('GET', `/api/config/safe`, {setLoading, onDone: setConfig})
    useEffect(getConfig, [getConfig])

    return <Container>
        <Stack direction={{sm:"column", md:"row"}} justifyContent="space-between" mb={4}>
            <AdminList />
            <LinkList />
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="start">
            {canUser('POST', '/api/config/fetch') ? <>
                <Typography>This runs every 10 minutes, but can be handy to run this after changing a parameter store
                    variable
                    for immediate effect.</Typography>
                <Button disabled={loading} onClick={() => fetchConfig()} color="warning">
                    Re-fetch Config from Parameter Store
                </Button>
                <Box sx={{overflowX:'auto', maxWidth:'100vw'}}>
                    <Table size="small" sx={{maxWidth:1000, my:4}}>
                        {config ? Object.keys(config).map(c => (
                            <ConfigParamRow key={c} name={c} value={config[c]}/>
                        )) : ''}
                    </Table>
                </Box>
            </> : ''}
        </Stack>
    </Container>
}

function LinkList() {
    const {showError, showSuccess, showWarning, showInfo} = useToastStore()
    const navigate = useNavigate()
    const {canUser} = useLoginStore()
    const {adminLinks} = useAppConfigStore()

    const toastTest = useCallback(() => {
        showSuccess('test success, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
        showError('test error, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
        showWarning('test warning, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
        showInfo('test info, lorum ipsum dolor sit amet, epidscing elit blah blah blah')
    }, [showError, showInfo, showSuccess, showWarning])

    const links = useMemo(() => {
        const _links = [
            {action: () => navigate('/api/errors'), name: 'Server Errors', path: '/api/errors'},
            {action: () => navigate('/api/autherrors'), name: 'Server Auth Errors', path: '/api/autherrors'},
            {action: () => navigate('/api/server'), name: 'Server Info', path: '/api/server'},
            {action: () => navigate('/api/groups'), name: 'Groups', path: '/api/groups'},
            {action: () => navigate('/api/users'), name: 'Users', path: '/api/users'},
            {action: () => navigate('/api/ws_clients'), name: 'Websocket Clients', path: '/api/ws_clients'},
            {action: toastTest, name: 'Toast Test', path: ''},
        ].filter(({path}) => canUser('GET', path))

        adminLinks?.forEach(al => {
            const action = () => navigate(al.action)
            _links.push({...al, action},)
        })

        return _links
    }, [canUser, navigate, toastTest, adminLinks])

    return <Stack direction="column" spacing={1} justifyItems="right">
        {links.map(({action, name}) => (
            <Button key={name} onClick={action}>{name}</Button>
        ))}
    </Stack>
}

function AdminList() {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState('')
    const {canUser, id: me} = useLoginStore()

    const fetchUsers = useApiCallback('GET', `/api/admins`, {setLoading, onDone: setUsers})
    useEffect(fetchUsers, [fetchUsers])

    const addUser = useApiCallback('POST', `/api/admin`, {
        data: {user: username},
        setLoading,
        onDone: fetchUsers
    })
    const removeUser = useApiCallback('DELETE', `/api/admin`, {
        data: {user: username},
        setLoading,
        onDone: fetchUsers
    })

    return <Box mb={4}>
        <Typography variant="h5">Admins</Typography>
        {canUser('POST', '/api/admins')
            ? <Stack direction="row" alignItems="center" mb={1}>
                <TextField
                    label="Name"
                    InputLabelProps={{shrink: true}}
                    value={username}
                    onChange={(event) => {
                        setUsername(event.target.value)
                    }}
                    sx={{width: '300px'}}
                />
                <Button disabled={loading || !username} onClick={() => addUser()}>Add</Button>
                <Button disabled={loading || !username} onClick={() => removeUser()}>Delete</Button>
                <Button disabled={loading} onClick={() => setUsername(me)}>Me</Button>
            </Stack>
            : ''}
        {users.map(k => (
            <Button key={k} sx={buttonStyle} onClick={() => setUsername(k)}>{k}</Button>)
        )}
    </Box>
}

function ConfigParamRow({name, value}) {
    const [newVal, setNewVal] = useState(String(value))
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(<></>)
    const [editing, setEditing] = useState(false)

    const setParam = useApiCallback('POST', '/api/config/param', {
        data: {name, value:newVal},
        setLoading,
        onDone: () => {
            setDone('saved!')
            setEditing(false)
            setTimeout(() => setDone(''), 1000)
        }
    })

    return (
        <TableRow sx={{fontFamily:'monospace'}}>
            <TableCell align="right" sx={{fontFamily:'monospace'}}>{name}</TableCell>
            <TableCell sx={{fontFamily:'monospace'}}>{typeof(value)}</TableCell>
            <TableCell sx={{fontFamily:'monospace'}}>
                {editing
                    ? (
                        <TextField
                            autoFocus
                            value={newVal}
                            onChange={(event) => {
                                setNewVal(event.target.value)
                            }}
                            onBlur={() => {
                                if (newVal === String(value)) {
                                    setEditing(false)
                                }
                            }}
                        />
                    )
                    : <ConfigValue value={newVal} onClick={() => setEditing(true)} />
                }
                <Button
                    disabled={loading}
                    sx={{visibility:newVal === String(value)?'hidden':''}}
                    onClick={() => setParam()}
                >Save</Button>
                {done}
            </TableCell>
        </TableRow>
    )
}