/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu'
import {useTheme} from '@mui/material/styles';
import {useState, useRef, useCallback, useMemo} from "react";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import {useLoginStore} from "../Login";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutIcon from "@mui/icons-material/Logout";
import {getServer} from "../utils/server";
import {useServiceWorker} from "../useServiceWorker";
import {useNavigate} from "react-router-dom";
import {useAppConfigStore} from "../AppConfigStore";
import CloseIcon from '@mui/icons-material/Close';
import List from "@mui/material/List";
import {useApiCallback, useApiStore} from "../utils/Api";
import {Button} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import {useGlobalAppTheme} from "../utils/useGlobalAppTheme";
import VersionStr from "./VersionStr";


const CustomList = ({children, ...others}) => {
    const theme = useTheme();
    return <List sx={{
        padding: '0 !important',
        borderRadius: '25px',
        '& .MuiListItem-root': {
            backgroundColor: `${theme.palette.background.paper} !important`,
            paddingTop: '3px',
            marginTop: '1px',
            marginBottom: '1px',
            height: '36px',
            cursor: 'pointer',
            '&:hover': {
                opacity: '0.8'
            }
        },
        '& .MuiListItem-root:first-of-type': {
            paddingTop: '2px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
        },
        '& .MuiListItem-root:last-of-type': {
            paddingBottom: '3px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
        }
    }} {...others}>{children}</List>
}

const UserMenu = () => {
    const server = getServer()
    const theme = useTheme();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const {helplink} = useAppConfigStore()
    const anchorElement = useRef();
    const {updateReady, reloadPage} = useServiceWorker()
    const loginStore = useLoginStore()
    const {globalAppTheme, setGlobalAppTheme} = useGlobalAppTheme()
    const {setUser, id, user} = loginStore;

    const handleToggle = (event) => {
        setOpen(!open);
    };

    const openHelpClick = useCallback(() => {
        window.open(helplink, "_blank")
        setOpen(false)
    }, [helplink])

    const preferencesClick = useCallback(() => {
        navigate('preferences')
        setOpen(false)
    }, [navigate])

    const welcomeText = useMemo(() => `Hi, ${user.split(" ")[0]}!`, [user]);
    const userAvatarSrc = useMemo(() => `${server}/api/person/avatar/${id}`, [server, id])

    const onLogoutComplete = useCallback(() => {
        console.log("logged out")
        setUser(null)
    }, [setUser])
    const logoutClick = useApiCallback('GET', `/api/logout`, {onDone: onLogoutComplete})
    const handleLogoutClick = useCallback(() => logoutClick(), [logoutClick])

    // Change theme drop down stuff
    const [anchorElChangeTheme, setAnchorElChangeTheme] = useState(null)
    const changeThemeOpen = Boolean(anchorElChangeTheme)
    const handleClickOpenChangeTheme = (event) => {
        setAnchorElChangeTheme(event.currentTarget)
    };
    const handleCloseChangeTheme = () => {
        setAnchorElChangeTheme(null)
    };
    const handleChangeTheme = (theme) => {
        setGlobalAppTheme(theme)
        setAnchorElChangeTheme(null)
    };
    const getMenuWidth = (anchorEl) => {
        if (!anchorEl) return null
        return anchorEl.clientWidth + 'px' // Get anchor element width and add px
    };

    const {asUser, setAsUser} = useApiStore()

    return <>
        <Tooltip title="Open settings">
            <IconButton onClick={handleToggle} ref={anchorElement} sx={{m: 0, ml: 2, mr: 2}}>
                <Avatar alt={loginStore.user} src={userAvatarSrc} variant="circular"/>
            </IconButton>
        </Tooltip>
        <Menu
            id="menu-usermenu"
            anchorEl={() => anchorElement.current}
            open={open}
            onClose={handleToggle}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '24px',
                    padding: '8px',
                    paddingTop: '0px',
                    backgroundColor: theme.palette.background.default,
                    backgroundImage: 'none',
                    borderWidth: '10px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.background.paper,
                    width: '320px'
                },
                '& .MuiList-root': {
                    backgroundColor: theme.palette.background.default,
                    paddingLeft: "8px",
                    paddingRight: "8px",
                }
            }}
        >
            <ListItem p={0}>
                <Typography
                    className="UserFullName"
                    color="tertiary.contrastText"
                    sx={{textAlign: 'center', width:'100%'}}
                >
                    {loginStore.id}
                </Typography>
            </ListItem>

            <ListItem>
                <Avatar alt={loginStore.user} src={userAvatarSrc} variant="circular" sx={{margin: '0 auto', height: '64px', width: '64px'}}/>
            </ListItem>

            <ListItem>
                <ListItemText sx={{margin: '0 auto'}}>
                    <Typography color="tertiary.contrastText" align="center">
                        <strong>{welcomeText}</strong>
                    </Typography>
                </ListItemText>
            </ListItem>

            <Divider sx={{height:20}}/>

            <CustomList>
                <ListItem
                    onClick={handleClickOpenChangeTheme}
                    sx={{
                        width: '100%',
                        cursor: 'pointer',
                        textTransform: 'none !important',
                        marginTop: '10px',
                        marginBottom: '10px',
                        borderRadius: '8px',
                        borderColor: 'none',
                        boxShadow: 'none',
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <ListItemIcon>
                        <DarkModeOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        Appearance: {globalAppTheme}
                    </ListItemText>
                </ListItem>
                <Menu
                    anchorEl={anchorElChangeTheme}
                    open={changeThemeOpen}
                    onClose={handleCloseChangeTheme}
                    slotProps={{
                        paper: {
                            sx: {width: getMenuWidth(anchorElChangeTheme)},
                        }
                    }}
                >
                    <MenuItem onClick={() => handleChangeTheme('system')}>
                        <DarkModeOutlinedIcon/>
                        <Typography ml={4} variant="body">Use System Theme</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleChangeTheme('light')}>
                        <LightModeOutlinedIcon/>
                        <Typography ml={4} variant="body">Light</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleChangeTheme('dark')}>
                        <DarkModeOutlinedIcon/>
                        <Typography ml={4} variant="body">Dark</Typography>
                    </MenuItem>
                </Menu>
            </CustomList>

            <Divider sx={{height:10}}/>

            <CustomList>
                {updateReady ? <ListItem key='UpdateReady' sx={{color:theme.palette.success.main}} onClick={_ => {
                    reloadPage();
                    setOpen(false)
                }}>
                    <ListItemIcon>
                        <BrowserUpdatedIcon/>
                    </ListItemIcon>
                    <ListItemText>Update Available</ListItemText>
                </ListItem> : ''}
                <ListItem key='Reload' onClick={_ => {
                    reloadPage();
                    setOpen(false)
                }}>
                    <ListItemIcon>
                        <RefreshIcon/>
                    </ListItemIcon>
                    <ListItemText>Reload App</ListItemText>
                </ListItem>
                <ListItem key="Help" onClick={openHelpClick}>
                    <ListItemIcon>
                        <HelpOutlineOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        Help
                    </ListItemText>
                </ListItem>
                <ListItem key="Preferences" onClick={preferencesClick}>
                    <ListItemIcon>
                        <ManageAccountsOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        Preferences
                    </ListItemText>
                </ListItem>

                <ListItem key='Logout' onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText>Sign out</ListItemText>
                </ListItem>

                {asUser
                    ? (
                        <ListItem key='Clear AsUser' onClick={() => setAsUser('')}>
                            <ListItemIcon sx={{color:'red'}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText>Clear AsUser</ListItemText>
                        </ListItem>
                    )
                    : ''
                }

            </CustomList>
            <VersionStr/>
        </Menu>
    </>
};

export default UserMenu;
