/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {create} from "zustand";

export const useGlobalAppTheme = create((set) => ({
    globalAppTheme: localStorage.getItem(`global_app_theme`) || 'dark',
    loadGlobalAppTheme: () => set(() => ({
        globalAppTheme: localStorage.getItem(`global_app_theme`) || 'dark'
    })),
    setGlobalAppTheme: (globalAppTheme) => set(() => {
        localStorage.setItem('global_app_theme', globalAppTheme)
        return {globalAppTheme}
    }),
}))
