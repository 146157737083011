/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React, {useEffect, useMemo} from "react";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {Helmet} from 'react-helmet-async';
import {Routes, Route} from "react-router-dom";
import {LicenseInfo} from '@mui/x-license-pro';
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import './App.css';
import Toast from './Toast';
import AppBar from './AppBar';
import {useToastStore} from "./Toast";
import {LoginState, useLoginStore} from "./Login";
import ErrorBlock from './component/ErrorBlock';
import ThemeProvider from './ThemeProvider';
import {BASEPATH, useAppConfigStore} from "./AppConfigStore";
import {useServiceWorker} from './useServiceWorker';
import WebSock from "./service/WebSock";
import Preferences from "./panel/Preferences";
import SideDrawer, {useSideDrawerStore} from "./component/SideDrawer";
import LoginForm from "./panel/LoginForm";
import {Api} from "./panel/Api";

LicenseInfo.setLicenseKey('473f7a35c4c96267b7e044104445562dTz04NjE4OSxFPTE3NDM3ODI0MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function App() {
    const loginstore = useLoginStore()
    const {pages, name, backgroundComponents} = useAppConfigStore()
    const infoToast = useToastStore((state) => state.showInfo)
    const {waitingWorker, showReload, reloadPage} = useServiceWorker()
    const {drawerWidth} = useSideDrawerStore();
    const {permissions, user, canUser} = loginstore;

    // decides when to show the toast
    useEffect(() => {
        if (showReload && waitingWorker) {
            infoToast(
                <div>
                    New app version available!
                    <Button style={{marginLeft: '5px'}} onClick={() => reloadPage()}>Reload</Button>
                </div>
                , 9999999)
        }
    }, [infoToast, waitingWorker, showReload, reloadPage]);

    const routes = useMemo(() => {
        if (!user) {
            return []
        }
        const routes = [
            <Route key={'/preferences'} path='/preferences' element={<Preferences/>}/>,
        ]
        Object.keys(pages).forEach(k => {
            const [, path, elem, opts] = pages[k]
            console.log('ACL', path, elem, 'opts:', opts)
            if (opts?.allusers || canUser('GET', '#' + path, permissions)) {
                routes.push(<Route key={path} path={path} element={elem}/>)
            }
        })
        return routes
    }, [permissions, pages, canUser, user])

    let headers, coname
    if (window.location.host.match(/\.esao\.aero(:\d+)?$/)) {
        coname = 'European SAO Ltd "ESAO"'
        headers = <>
            <title>ESAO {name}</title>
            <meta name="description" content={"ESAO " + name}/>
            <meta name="theme-color" content="#24305f"/>
            <meta name="author" content={coname}/>
        </>
    } else {
        coname = 'DEA Aviation Ltd'
        headers = <>
            <title>DEA {name}</title>
            <meta name="description" content={"DEA " + name}/>
            <meta name="theme-color" content="#333333"/>
            <meta name="author" content={coname}/>
        </>
    }
    const apiUrl = window.location.pathname.match('^/.+(/api/.*)$')
    if (apiUrl) {
        window.location = BASEPATH + '#' + apiUrl[1]
        return 'Redirecting to app (#1)...'
    }
    if (!window.location.search && (!window.location.hash || window.location.hash === '#')) {
        window.location = BASEPATH + '#/';
        // dont block loading - changing just the hashpath wont reload the page.
    }
    if (window.location.pathname !== BASEPATH) {
        window.location.pathname = BASEPATH
        return 'Redirecting to app (#3)...'
    }

    return <>
        <Helmet>
            {headers}
        </Helmet>
        <ThemeProvider>
            <ErrorBlock name="LoginState">
                <LoginState/>
            </ErrorBlock>
            <ErrorBlock name="Appbar">
                <AppBar/>
            </ErrorBlock>
            <CssBaseline/>
            <Box
                sx={{flexGrow: 1, p:2, position:'absolute', height: 'calc(100dvh - 55px)',
                    transition: 'margin-left 0.2s',
                    marginLeft: {xs: 0, lg: `${drawerWidth}px`}
                }}
            >
                <ErrorBlock name="Custom app components">
                    {backgroundComponents}
                </ErrorBlock>
                <ErrorBlock name="Websocket">
                    <WebSock/>
                </ErrorBlock>
                <Toast/>
            </Box>
            <Box className="side-drawer-wrapper" sx={{
                position: 'absolute',
                padding: 0,
                margin: 0,
                marginTop: '55px',
                overflowY: 'auto',
                overflowX: 'hidden',
                left: '0',
                right: '0',
            }}>
                <Box
                    component="nav"
                    sx={{
                        transition: 'width 0.2s',
                        width: drawerWidth,
                        flexShrink: 0,
                    }}
                    aria-label="side drawer"
                >
                    <ErrorBlock name="SideDrawer">
                        <SideDrawer/>
                    </ErrorBlock>
                </Box>
                <Box
                    sx={{
                        transition: 'margin-left 0.2s',
                        marginLeft: {xs: 0, lg: `${drawerWidth}px`},
                    }}
                    aria-label="side drawer"
                >
                    <ErrorBlock name="Main panel">
                        <Box
                            component="main"
                            sx={{flexGrow: 1, p:2, position:'relative', height: 'calc(100dvh - 55px)',}}
                        >
                            <Routes>
                                <Route key="api" path="api/*" element={<Api/>}/>
                                {routes}
                                <Route key="any" path="/*" element={<PageNotFound/>}/>
                            </Routes>
                            {/*<footer className="bg-black p-8" style={{display: 'none'}}>*/}
                            {/*    <div className="text-center mt-4">© Copyright 2024 {coname} - All Rights Reserved</div>*/}
                            {/*</footer>*/}
                        </Box>
                    </ErrorBlock>
                </Box>
            </Box>
        </ThemeProvider>
    </>
}

const PageNotFound = () => {
    const {user} = useLoginStore()
    return <Container sx={{textAlign: 'center', pt:5}}>
        <Typography variant="h5">{user ? 'Page not found' : <LoginForm/>}</Typography>
        <em>{user ? 'You may not have permission to access the requested page' : ''}</em>
    </Container>
}
