import React, {useEffect, useMemo, useState} from "react"
import useConfigStore from '../utils/useConfigStore'
import {useParams} from "react-router-dom"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link";
import {useApiCallback} from "../utils/Api";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import {useLoginStore} from "../Login";

export default function Client() {
    const {client} = useParams()
    const {childMportals} = useConfigStore()
    const [info, setInfo] = useState({})
    const [username, setUsername] = useState('')
    const [domain, setDomain] = useState('')
    const {canUser} = useLoginStore()

    const childMportal = useMemo(() => {
        return childMportals.find(c => c.client === client)?.domain
    }, [childMportals, client])

    const fetchInfo = useApiCallback('GET', `/api/childmportal/${client}`, {onDone: setInfo, ignoreErrors:true})
    useEffect(() => {
        if (canUser(`/api/childmportal/${client}`))
            fetchInfo()
    }, [fetchInfo]);

    useEffect(() => {
        setDomain(info['domain'])
        setUsername(info['person'])
    }, [info]);

    const data = useMemo( () => ({username, client, domain}), [client, domain, username])
    const delegate = useApiCallback('POST', `/api/childmportal`, {onDone: setInfo, data})

    const deleteClient = useApiCallback('DELETE', `/api/childmportal/${client}`, {onDone: () => setInfo({})})

    const allowedSet = !childMportal && canUser('POST', `/api/childmportal/${client}`)

    const childMportalLink = childMportal
        ? <>
            This client is associated with a dedicated mPortal:&nbsp;
            <Link href={`https://${childMportal}`} target="_blank" sx={{lineHeight:'150%'}}>
                {childMportal}
            </Link>
        </>
        : <>
            <Typography sx={{display: 'inline-block', ml: 4}}>
                This client is not associated with a dedicated mPortal.
            </Typography>
        </>;

    return <Container>
        <Stack>
            <Typography variant="h4">Client: {client}</Typography>

            <Stack direction="row" alignContent="center" my={3}>
                {childMportalLink}
            </Stack>

            <Stack direction="row" alignContent="center">
                <TextField
                    label="Username"
                    InputLabelProps={{shrink: true}}
                    value={username}
                    disabled={!allowedSet}
                    onChange={(event) => {
                        setUsername(event.target.value.toLowerCase())
                    }}
                />
                <TextField
                    label="Domain"
                    InputLabelProps={{shrink: true}}
                    value={domain}
                    disabled={!allowedSet}
                    onChange={(event) => {
                        setDomain(event.target.value.toLowerCase())
                    }}
                />
                <Button disabled={!allowedSet} onClick={() => delegate()}>Delegate to child mportal</Button>
                <Button disabled={allowedSet} onClick={() => deleteClient()}>Delete child mportal link</Button>
            </Stack>

            <pre>{JSON.stringify(info, null, 4)}</pre>
        </Stack>
    </Container>
}
