/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
/**
 * Example usage:
 *
 * import {create} from "zustand";
 * import Acl from './panel/Acl'
 * import Admins from './panel/Admins'
 * import Query from './panel/Query'
 *
 * export const DEVMODE = window.location.hostname.match('localhost')
 * export const BASEPATH = DEVMODE ? '/app/' : '/my_custom_app/'
 *
 * export const useAppConfigStore = create(() => ({
 *     name: 'My App',
 *     helplink: 'https://sites.google.com/dea.aero/my_app',
 *     pages: [
           ['HomePage',     '/',                <HomePage/>],
 *         ['ACL',          '/acl',             <Acl/>],
           ['Admins',       '/admins',          <Admins/>],
 *         ['Query',        '/query',          <Query/>],
 *     ],
 *     preload: [],
 * }))
 */


// @uncommon
import {create} from "zustand";
import Acl from "./panel/Acl";
import Admins from "./panel/Admins";
import Map from "./panel/Map";
import IPMap from "./panel/IPMap";
import New from "./panel/New";
import Compare from "./panel/Compare";
import Setup from "./panel/Setup";
import Import from "./panel/Import";
import Variables from "./panel/Variables";
import Uploads from "./panel/Uploads";
import Streams from "./panel/Streams";
import Stats from "./panel/Stats";
import Config from "./panel/Config";
import {VideoRoute} from "./panel/Video";
import Home from "./panel/Home";
import GroupInfo from "./panel/GroupInfo";
import PersonInfo from "./panel/PersonInfo";
import WebSockHandler from "./service/WebSockHandler";
import ErrorBlock from "./component/ErrorBlock";
import Query from './panel/Query'
import Modems from "./panel/Modems";
import Client from "./panel/Client";

import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import Debug from "./panel/Debug";
import Spectrum from "./panel/Spectrum";
import Watchers from "./panel/Watchers";

export const DEVMODE = window.location.hostname.match('localhost')
export const BASEPATH = DEVMODE ? '/app/' : '/mportal/'
export const PWLOGIN = false;

export const useAppConfigStore = create(() => ({
    name: 'mPortal',
    helplink: 'https://sites.google.com/dea.aero/mportal',
    pages: [
        ["Home",         "/",                   <Home/>,          {icon: <HomeOutlinedIcon/>}],
        ["Map",          "/map",                <Map/>,           {icon: <MapOutlinedIcon/>}],
        ["IPMap",        "/ipmap",              <IPMap/>,         {icon: <DnsOutlinedIcon/>}],
        ["New",          "/new",                <New/>,           {icon: <AddCircleOutlineOutlinedIcon/>}],
        ["Compare",      "/compare",            <Compare/>,       {icon: <CompareArrowsOutlinedIcon/>}],
        ["Setup",        "/setup/:identity",    <Setup/>,         {hide: true}],
        ["Import",       "/import",             <Import/>,        {icon: <CloudDownloadOutlinedIcon/>}],
        ["Variables",    "/variables",          <Variables/>,     {icon: <TuneOutlinedIcon/>}],
        ["Uploads",      "/uploads",            <Uploads/>,       {icon: <CloudUploadOutlinedIcon/>}],
        ["Streams",      "/streams",            <Streams/>,       {icon: <LiveTvOutlinedIcon/>}],
        ["Modems",       "/modems",             <Modems/>,        {icon: <SatelliteAltIcon/>}],
        ["Stats",        "/stats/:identity",    <Stats />,        {hide: true}],
        ["Config",       "/config/:identity",   <Config />,       {hide: true}],
        ["Video",        "/video/:identity",    <VideoRoute />,   {hide: true}],
        ['ACL',          '/acl',                <Acl/>,           {icon: <DashboardCustomizeOutlinedIcon/>}],
        ['Admins',       '/admins',             <Admins/>,        {icon: <AdminPanelSettingsOutlinedIcon/>}],
        ['Query',          '/query',            <Query/>,         {icon: <QueryStatsOutlinedIcon/>}],
        ['Debug',          '/debug',            <Debug/>,         {icon: <QueryStatsOutlinedIcon/>}],
        ['Spectrum',       '/spectrum',         <Spectrum/>,      {icon: <LiveTvOutlinedIcon/>}],
        ['GroupInfo',      '/group/:email',     <GroupInfo/>,     {hide: true}],
        ['PersonInfo',     '/person/:email',    <PersonInfo/>,    {hide: true}],
        ['Watchers',     '/watchers',           <Watchers/>,      {icon: <LiveTvOutlinedIcon/>}],
        ['Client',       '/client/:client',     <Client/>,        {hide: true}],
        // ["Flights",      "/flights",            <Flights/>],
    ],
    backgroundComponents: <>
        <ErrorBlock name="WebSockHandler"><WebSockHandler/></ErrorBlock>
    </>
}))
