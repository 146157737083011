import React from "react"
import useStatsStore from '../utils/useStatsStore'
import {useServerStore} from "../utils/useServerStore";

export default function Debug() {
    const {lastseen, lastseenip, assigned, assignments} = useStatsStore()
    const {servertime, servertimediff} = useServerStore()
    const localtimer = (Date.now() / 1000)

    return <pre>
        {JSON.stringify({localtimer, servertime, servertimediff, lastseen, lastseenip, assigned, assignments}, null, 4)}
    </pre>
}
