/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Ver from "../version.json"
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export default function VersionStr() {
    const [hash, timestamp] = Ver.split(" ")
    return <Stack sx={{textAlign: 'center', fontSize:14}} mt={3}>
        <Box>Build: <code>{hash}</code></Box>
        <Box>Built: <code>{timestamp}</code></Box>
    </Stack>
}
