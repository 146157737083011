import React, {useCallback, useEffect, useState} from "react"
import Stack from "@mui/material/Stack"
import {Link as RLink, useNavigate, useParams} from "react-router-dom"
import TextField from '@mui/material/TextField'
import {Button} from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import {Stat} from "./Stats"
import {useToastStore} from "../Toast"
import {useApiCallback} from "../utils/Api"
import useConfigStore from '../utils/useConfigStore'
import useStatsStore from '../utils/useStatsStore'
import ErrorBlock from "../component/ErrorBlock"
import useViewStore from "../utils/useViewStore";
import StatusRow from "./StatusRow";
import NonPrimaryWarning from "../component/NonPrimaryWarning";

export default function Config() {
    const params = useParams()
    const [identity, setIdentity] = useState(params.identity)
    const {configs, setConfigs, primaryHost} = useConfigStore()
    const {assigned} = useStatsStore()
    const [data, setData] = useState({'loading': 'please wait…'})
    const [filteredData, setFilteredData] = useState('')
    const {filter, setFilter} = useViewStore()
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    const fetchConfig = useApiCallback('GET', `/api/config?name=${identity}`, {setLoading, onDone: setData})
    const onDone = () => {
        setConfigs({...configs, identity:data})
        successToast('Configuration saved')
    }
    const saveConfig = useApiCallback('POST', `/api/config?name=${identity}`, {setLoading, onDone})
    const errorToast = useToastStore((state) => state.showError)
    const successToast = useToastStore((state) => state.showSuccess)

    useEffect(fetchConfig, [fetchConfig, identity])

    useEffect(() => {
        if (typeof filteredData === 'undefined') {
            return
        }
        setValid(false)
        if (primaryHost) {
            return
        }
        const t = setTimeout(() => {
            try {
                JSON.parse(filteredData)
                setValid(true)
            } catch (e) {
                console.error(e)
                errorToast(String(e))
                setValid(false)
            }
        }, 1000)
        return () => clearTimeout(t)
    }, [errorToast, filteredData, setValid])

    const saveClick = useCallback(() => {
        try {
            let dd = data
            if (filter) {
                data[filter] = JSON.parse(filteredData)
            } else {
                dd = JSON.parse(filteredData)
            }
            saveConfig(dd)
        } catch (e) {
            errorToast(String(e))
        }
    }, [data, errorToast, filter, filteredData, saveConfig])

    useEffect(() => {
        let fd
        if (!data) return
        if (filter) {
            if (data.hasOwnProperty(filter)) {
                fd = data[filter]
            } else {
                fd = {}
            }
        } else {
            fd = data
        }
        setFilteredData(JSON.stringify(fd, null, 2))
    }, [data, filter])

    const navigate = useNavigate()
    const nextClick = useCallback(({reverse=false}) => {
        let ll = Object.keys(assigned).sort()
        if (reverse) ll = ll.reverse()
        let useNext = false
        let changeto = null
        ll.some(ident => {
            if (useNext) {
                changeto = ident
                return true
            }
            if (ident === identity) {
                useNext = true
            }
            return false
        })
        if (useNext && !changeto) {
            changeto = ll[0]
        }
        setIdentity(changeto)
        navigate('/config/'+changeto)
    }, [assigned, identity, navigate])

    const prevClick = useCallback(() => {
        nextClick({reverse:true})
    }, [nextClick])

    return <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
        <Stack sx={{mt:2}} direction="row" alignItems="center" spacing={1} justifyContent="center">
            <Typography component="h2">{identity}</Typography>
            <Button disabled={loading || !valid} onClick={saveClick}>Save</Button>
            <Button component={RLink} to={`/setup/${identity}`}>Setup</Button>
            <Button component={RLink} to={`/stats/${identity}`}>Stats</Button>
            <TextField
                label="Filter"
                InputLabelProps={{shrink: true}}
                value={filter}
                onChange={(event) => {
                    setFilter(event.target.value)
                }}
                sx={{width: '300px', margin: 2}}
            />
            <Button onClick={prevClick}>&lt;</Button>
            <Button onClick={nextClick}>&gt;</Button>
        </Stack>
        <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
            fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
            <Grid item container xs={12} spacing={0}>
                <StatusRow setSelected={setIdentity} identity={identity} selected={identity} showClient={true}/>
            </Grid>
        </Grid>
        <NonPrimaryWarning/>
        <Box style={{height: 'calc(100vh - 155px)', width: '100%', overflowY:'auto'}}>
            <TextField
                label="JSON Configuration"
                InputLabelProps={{shrink: true}}
                multiline
                disabled={primaryHost || loading}
                value={filteredData}
                onChange={(event) => {
                    setFilteredData(event.target.value)
                }}
                rows={Math.min(filteredData.split("\n").length, 60)}
                InputProps={{
                    style: {fontFamily: 'Monospace', fontSize: 12, fontWeight: 'bold'},
                }}
                sx={{width: '99%', marginTop:3, backgroundColor: '#333'}}
            />
            { filter ?
            <ErrorBlock name="StatusData">
                <Stack item xs={3} direction="column" spacing={0} sx={{width: '100%', m:2}}>
                    <Stat identity={identity} module={filter} showTitle={identity + ' - ' + filter}/>
                </Stack>
            </ErrorBlock>
            : ''}
        </Box>
    </Stack>
}
