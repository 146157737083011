import {create} from "zustand";

const useStatsStore = create((set) => ({
    lastseen: {},
    lastseenip: {},
    stats: {},
    assigned: {},
    assignments: [],
    setLastSeen: (lastseen) => set({ lastseen }),
    setLastSeenIp: (lastseenip) => set({ lastseenip }),
    setStats: (stats) => set({ stats }),
    setAssigned: (assigned) => set({ assigned }),
    setAssignments: (assignments) => set({ assignments }),
}))
export default useStatsStore
