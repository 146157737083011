/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles'
import {useGlobalAppTheme} from "./utils/useGlobalAppTheme";
import {useEffect, useMemo} from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

const palette = {
    mode: 'dark',
    primary: {
        main: '#2e2e2e',
        contrast: '#444444',
        lighter: '#434343',
        paper: '#2e2e2e',
        contrastText: '#fff',
    },
    secondary: {
        main: '#3E455F',
        contrastText: '#6AFF0A',
    },
    tertiary: {
        main: '#34373B',
        contrastText: '#fff',
    },
    feature: {
        main: '#53E7BB',
        mainTranslucent: '#53E7BB70',
        contrastText: '#000',
    },
    text: {
        primary: '#E4E4E9',
    },
    info: {
        main: '#3E455F',
    },
    background: {
        default: '#000',
        paper: '#34373B',   // DEA Stealth Grey
    },
    success: {
        main: '#00CE9B',
        mainTranslucent: '#00CE9B70',
        contrastText: '#000',
    },
    deployed: '#5FE9C7',
    leave: '#e9c75f',
    wfh: '#FF9500',
    notdeployed: '#19b28c',
    ro: '#e95f81',
    training: '#5FC6E9',
}
const paletteLight = {
    mode: 'light',
    primary: {
        main: '#fff',
        contrast: '#e1e1e1',
        lighter: '#efefef',
        paper: '#ededed',
        contrastText: '#000',
    },
    secondary: {
        main: '#ccc',
        contrastText: '#01ce9b',
    },
    tertiary: {
        main: '#efefef',
        contrastText: '#000',
    },
    feature: {
        main: '#01ce9b',
        mainTranslucent: '#01ce9b80',
        contrastText: '#fff',
    },
    text: {
        primary: '#000',
    },
    info: {
        main: '#efefef',
    },
    background: {
        default: '#fff',
        paper: '#F2F2F7',
    },
    success: {
        main: '#00CE9B',
        mainTranslucent: '#00CE9B70',
        contrastText: '#000',
    },
    deployed: '#5FE9C7',
    leave: '#e9c75f',
    wfh: '#FF9500',
    notdeployed: '#19b28c',
    ro: '#e95f81',
    training: '#5FC6E9',
}

const spacing = (factor) => {
    const values = [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];
    const index = Math.floor(factor);
    const currentSpace = values[index];
    const nextSpace = values[index + 1] || currentSpace * 2;
    const space = currentSpace + (nextSpace - currentSpace) * (factor - index);
    return `${space}px`;
}

const components = {
    MuiCssBaseline: {
        styleOverrides: (theme) => ({
            body: {
                /* disable pull-down refresh */
                overscrollBehavior: 'none',
                margin: 0,
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
                    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
            },
            '::-webkit-scrollbar': {
                width: '0.7em',
            },
            '::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.primary.main,
                width: '0.8em',
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.tertiary.main, //'#444',
                // outline: '1px solid #111',
                borderRadius: '8px',
                width: '0.8em',
            },
            '::-webkit-scrollbar-thumb:hover': {
                outlineWidth: '1px',
                outlineStyle: 'solid',
                outlineColor: theme.palette.secondary.main,
            },
            // CALENDAR STYLING
            '.fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary': {
                display: "none"
            },
            'h2.fc-toolbar-title': {
                display: "none"
            },
            '.fc-col-header-cell': {
                background: theme.palette.background.default,
                textTransform: "uppercase",
                fontFamily: "'Montserrat', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "11px",
                fontWeight: "600",
                lineHeight: "13.41px",
                textAlign: "center",
            },
            '#root > div > main > div > div.MuiGrid-root.MuiGrid-container > div:nth-child(2) > h5': {
                fontFamily: "'Montserrat', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "24px",
                lineHeight: "29.26px",
                textAlign: "center",
            },
            '.fc-daygrid-day-frame': {
                background: theme.palette.background.paper
            },
            '.fc-theme-standard td, .fc-theme-standard th': {
                borderColor: theme.palette.background.default
            },
            '.fc-theme-standard .fc-scrollgrid': {
                borderColor: theme.palette.background.default
            },
            '.fc-scroller': {
                backgroundColor: `${theme.palette.background.default} !important`
            },
            '.fc-event-main': {
                lineHeight: "14px"
            },
            '.fc-daygrid-day-top': {
                flexDirection: "row !important",
                //fontFamily: "Roboto"
            },
            '.fc-daygrid-bg-harness .fc-event-title': {
                textAlign: "right"
                //margin-top: 36px
            },
            '.fc-daygrid-event-harness, .fc-daygrid-event-harness-abs': {
                //this makes each day-event bar sit at the bottom
                top: "25px !important"
            },
            '.fc-daygrid-event': {
                borderRadius: "40px !important",
                marginTop: "10px"
            },
            '.fc .fc-bg-event': {
                backgroundColor: `${theme.palette.background.paper}`,
                //opacity: 0.9
            },
            '.fc-day-today': {
                backgroundColor: "#00000000 !important"
            },
            '.fc .fc-bg-event .fc-event-title': {
                fontFamily: "'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "18.75px",
                marginTop: "5px;",
                //color: theme.palette.text.primary,

            },
            '#root > div.MuiBox-root > main > div > div.MuiBox-root > div > div > div > button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
                textTransform: "capitalize",
                fontFamily: "'Inter', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            },
            '.fc-daygrid-block-event': {
                pointerEvents: "none",
                height: "14px",
            },
            '#ts-pay-summary' : {
                fontFamily: "'Montserrat', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
            },
            '#ts-pay-summary>strong' : {
                fontWeight: "800"
            },
            '.event-submitted': {
                pointerEvents: "none",
                marginTop: "-55px !important",
                fontSize: "18px",
                textAlign: "center",
                '&:hover': {
                    background: "none"
                }
            },
            '.event-submitted-flight': {
                pointerEvents: "none",
                marginTop: "-40px !important",
                textAlign: "center",
                '&:hover': {
                    background: "none"
                }
            },
            '.fc-daygrid-event-dot': {
                display: "none"
            },
            code: {
                fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
            }
        }),
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                marginTop: 0,
                marginBottom: 0,
                marginRight: 4,
            }
        },
        defaultProps: {
            margin: 'dense',
            color: 'feature',
        },
    },
    MuiMenu: {
        styleOverrides: {
            root: ({theme}) => ({
                backgroundColor: theme.palette.main,
            }),
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: ({theme}) => ({
                '&.Mui-checked:not(.Mui-disabled)': {
                  color: theme.palette.feature.main,
                }
            })
        }
    },
    MuiTextField: {
        defaultProps: {
            size: "small",
            margin: 'dense',
            variant: "outlined",
            color: 'feature',
        },
    },
    MuiLink: {
        styleOverrides: {
            root: ({theme}) => ({
                /* make those links easier to press on mobile devices */
                color: theme.palette.feature.main,
                lineHeight: '32px',
                [theme.breakpoints.down('sm')]: {
                    lineHeight: '24px',
                },
            }),
        }
    },
    MuiRouterLink: {
        styleOverrides: {
            root: ({theme}) => ({
                /* make those links easier to press on mobile devices */
                color: theme.palette.feature.main,
            }),
        }
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                '&.Mui-focused': {
                    // color: '#666',
                },
            },
        },
    },
    MuiDrawer: {
        styleOverrides: {
            root: ({theme}) => ({
                "& .MuiDrawer-modal": {
                    /* apparently this isnt working. */
                },
                "& .MuiDrawer-paper": {
                    zIndex: 900,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.primary.main,
                    backgroundImage: 'none',
                },
                "& .MuiListItem-root": {
                    backgroundColor: theme.palette.primary.main,
                },
                "& .MuiListItemButton-root": {
                    '&:hover': {
                        backgroundColor: theme.palette.success.mainTranslucent
                    },
                },
                "& .MuiList-root": {
                    backgroundColor: theme.palette.primary.main,
                },
            }),
        },
    },
    MuiDataGrid: {
        defaultProps: {
            rowHeight: 30,
        },
        styleOverrides: {
            root: ({theme}) => ({
                marginTop: 20,
                marginBottom: 20,
                borderColor: theme.palette.secondary,
                backgroundColor: theme.palette.secondary,
            }),
            row: ({theme}) => ({
                "&.Mui-selected": {
                    backgroundColor: theme.palette.secondary.main,
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    },
                },
                "&:hover": {
                    backgroundColor: theme.palette.secondary.light,
                },
            }),
            cell: ({theme}) => ({
                borderColor: theme.palette.secondary,
                '.MuiDataGrid-booleanCell[data-value="true"]': {
                    color: theme.palette.success.main,
                },
                '.MuiDataGrid-booleanCell[data-value="false"]': {
                    color: theme.palette.error.main,
                },
            }),
            columnHeaders: ({theme}) => ({
                borderColor: theme.palette.secondary,
            }),
            columnHeader: ({theme}) => ({
                borderColor: theme.palette.secondary,
            }),
        }
    },
    MuiModal: {
        styleOverrides: {
            root: ({theme}) => ({
                '>.ModalInner': {
                    backgroundColor: theme.palette.primary.main,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    padding: '20px',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                    boxShadow: '0 0 20px black',
                    maxWidth: '95vw',
                    maxHeight: '95dvh',
                    overflow: 'hidden',
                    component: "form",
                    noValidate: 'on',
                    autoComplete: 'off',
                },
                bgcolor: theme.palette.primary.main,
                borderWidth: '2px',
                borderSyle: 'solid',
                borderColor: theme.palette.primary.dark
            }),
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                marginRight: 4,
            }
        },
        defaultProps: {
            variant: 'contained',
        },
    },
    MuiFilledInput: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiFormHelperText: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiIconButton: {
        defaultProps: {
            size: 'small',
        },
    },
    MuiInputBase: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiInputLabel: {
        defaultProps: {
            margin: 'dense',
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                // backgroundColor: palette.primary.lighter,
            }
        },
    },
    MuiList: {
        styleOverrides: {
            root: ({theme}) => ({
                backgroundColor: theme.palette.primary.light,
            }),
        },
        defaultProps: {
            dense: 'true',
        },
    },
    MuiOutlinedInput: {
        defaultProps: {
            size: 'small',
            margin: 'dense',
        },
        styleOverrides: {
            root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    // border: 'black 1.5px solid',
                },
                '&.Mui-focused': {
                    // border: 'red 1px solid',
                },
            }
        }
    },
    MuiFab: {
        defaultProps: {
            size: 'small',
        },
    },
    MuiTable: {
        defaultProps: {
            size: 'small',
        },
    },
    MuiAppBar: {
        styleOverrides: {
            root: ({theme}) => ({
                /* @see https://github.com/mui/material-ui/issues/41602 */
                backgroundImage: 'none',
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,
                shadows: 'none',
            })
        }
    },
    MuiToolbar: {
        defaultProps: {
            variant: 'dense',
        },
    },
    MuiTab: {
        styleOverrides: {
            root: ({theme}) => ({
                '&.MuiTab-root': {
                    color: theme.palette.primary.contrastText
                },
                '&.Mui-selected': {
                    color: theme.palette.feature.main,
                },
            })
        }
    },
    // MuiGrid: {
    //     styleOverrides: {
    //         root: ({theme}) => ({
    //             '& .fc-button.fc-prev-button': {
    //                 display: "none"
    //             },
    //         })
    //     }
    // }
}

const darkTheme = responsiveFontSizes(createTheme({
    palette,
    spacing,
    components,
}));
const lightTheme = responsiveFontSizes(createTheme({
    palette: paletteLight,
    spacing,
    components,
}))

export default function Main({children}) {
    const {globalAppTheme, loadGlobalAppTheme} = useGlobalAppTheme()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo(() => (globalAppTheme === 'dark' || (globalAppTheme === 'system' && prefersDarkMode))
        ? darkTheme
        : lightTheme
    , [globalAppTheme, prefersDarkMode]);

    useEffect(() => {
        const f = e => {
            if (e.key === 'global_app_theme') {
                loadGlobalAppTheme()
            }
        }
        window.addEventListener("storage", f)
        return () => window.removeEventListener('storage', f)
    }, [loadGlobalAppTheme])

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
