import {useEffect, useMemo} from "react";
import useStatsStore from '../utils/useStatsStore'
import useConfigStore from '../utils/useConfigStore'
import {useLoginStore} from "../Login";
import {useGarminStore} from "../Garmin";
import {useWebsocketStore} from "./WebSock";

export default function WebSockHandler() {
    const {stats, setLastSeen, lastseen, lastseenip, setStats, setLastSeenIp, setAssigned, setAssignments} = useStatsStore()
    const {setStreams, setPrimaryHost, setChildMportals, setIpMap, addSetupLogs, setConfigs: configStoreSetConfigs} = useConfigStore()
    const {setImage, setPermissions} = useLoginStore()
    const {addEvent} = useGarminStore()
    const {lastMessage} = useWebsocketStore()

    const data = useMemo(() => lastMessage ? JSON.parse(lastMessage.data) : null, [lastMessage]);

    useEffect(() => {
        if (data === null) return
        if (typeof data.stats !== 'object') {
            return;
        }
        const updateLastSeen = {...lastseen}
        Object.keys(data.stats).forEach(identity => {
            const modules = Object.keys(data.stats[identity])
            modules.forEach(module => {
                const {at} = data.stats[identity][module]
                if (typeof updateLastSeen[identity] === 'undefined') {
                    updateLastSeen[identity] = at
                } else {
                    updateLastSeen[identity] = Math.max(at, updateLastSeen[identity])
                }
                if (typeof stats[identity] === 'undefined') {
                    stats[identity] = {}
                }
                stats[identity] = {...stats[identity], [module]: data.stats[identity][module]};
            })
        })
        setLastSeen(updateLastSeen)
        setStats(stats)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.lastseen === 'object') {
            return setLastSeen({...lastseen, ...data?.lastseen})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.primary_mportal === 'string') {
            console.log('primary_mportal set to', data?.primary_mportal)
            return setPrimaryHost(data.primary_mportal)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.child_mportals === 'object') {
            console.log('child_mportals set to', data?.child_mportals)
            return setChildMportals(data.child_mportals)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.lastseenip === 'object') {
            return setLastSeenIp({...lastseenip, ...data?.lastseenip})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.assigned === 'object') {
            return setAssigned(data?.assigned)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.configs === 'object') {
            return configStoreSetConfigs(data?.configs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.ipmap === 'object') {
            return setIpMap(data?.ipmap)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.setupmsg === 'object') {
            return addSetupLogs(data.setupmsg.identity, data.setupmsg.logs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.assignments === 'object') {
            console.log('ws setting assignments')
            setAssignments(data.assignments)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.identity === 'object') {
            console.log('ws setting identity')
            setImage(data.identity.image)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.garmin === 'object') {
            console.log('ws garmin message received')
            addEvent(data.garmin)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.streams === 'object') {
            console.log('ws setting streams')
            setStreams(data.streams)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (typeof data?.permissions === 'object') {
            console.log('ws setting user permissions')
            setPermissions(data.permissions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return <></>
}
