/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Button} from "@mui/material";

import {useApiCallback} from "../utils/Api";

export const Api = () => {
    const [result, setResult] = useState('');
    const loc = useLocation()
    const [inc, setInc] = useState(0)
    const run = useApiCallback('GET', loc.pathname+loc.search, {onDone: setResult, returnAll: true});
    useEffect(() => run(), [run, inc]);
    return <>
        <Button onClick={() => setInc(inc+1)} sx={{position:'absolute', top:20, right:20}}>Reload</Button>
        <pre style={{fontSize: '0.8em'}}>{JSON.stringify(result, null, 2)}</pre>
    </>
}
