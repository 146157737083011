/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
export function getServer() {
    // NOTE that this is .hostname which CUTS OFF the port, so :3000 will disappear.
    return `https://${window.location.hostname}${window.location.pathname}`.replace(/\/$/,'')
}

export const ucFirst = s => s.charAt(0).toUpperCase() + s.slice(1);

// @uncommon

// @TODO this should be fetched from server.
export const dataServer = window.location.host.indexOf('.isr.') > 0 ? `data.${window.location.host}` : 'videv.dea.aero'

export const wssVideoPath = dataServer.indexOf('.isr.') > 0 ? `wss://${dataServer}` : `wss://${dataServer}:8443`
