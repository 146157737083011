/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import Stack from "@mui/material/Stack"
import {useLoginStore} from "../Login";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useApiCallback} from "../utils/Api";
import {useEffect, useMemo, useState} from "react";
import {AuthorityChip} from "./Preferences";
import {useNavigate, useParams} from "react-router-dom";
import {Link} from "@mui/material";

const PersonLink = ({person, navigate}) => (
    <Link color="inherit"
          sx={{lineHeight: '160%', cursor: 'pointer'}}
          onClick={() => navigate(`/person/${person}`)}
    >{person}</Link>
)

export default function GroupInfo() {
    const {email} = useParams()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {canUser} = useLoginStore()
    const canUserSeeGroup = canUser('GET', `/api/group/members/${email}`)

    const [members, setMembers] = useState({});
    const getGroup = useApiCallback('GET', `/api/group/members/${email}`, {setLoading, onDone: setMembers})
    useEffect(() => {
        setMembers({})
        if (canUserSeeGroup) getGroup()
    }, [canUserSeeGroup, getGroup]);

    const sorted = useMemo(() => {
        const _ord = ['owner', 'manager', 'member']
        const arr = Object.keys(members)
        arr.sort(
            (a, b) => members[a] === members[b] ? a - b : _ord.indexOf(members[a]) - _ord.indexOf(members[b])
        )
        return arr
    }, [members])

    return <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
        <Box container>
            <Typography variant="h5">User Group: <em>{email}</em></Typography>
            <Box mt={3}>
            {!canUserSeeGroup ? 'You do not have permission to view group members' : ''}
                {loading? 'loading…': ''}
                {sorted.map(person => (
                    <Box sx={{display: 'flex'}} key={person}>
                        <AuthorityChip authority={members[person]}/>
                        <PersonLink navigate={navigate} person={person}/>
                    </Box>
                ))}
            </Box>
        </Box>
    </Stack>
}
