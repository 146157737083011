/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {useState, useEffect, useCallback, useMemo} from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import {
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarContainer,
    gridFilteredSortedRowIdsSelector,
    selectedGridRowsSelector,
} from '@mui/x-data-grid-pro';
import {formatDate} from "./Export";
import {minutesToTimeStr} from "../utils/time";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatPerson = cell => cell.value ? `${cell.value.name} <${cell.value.email}>` : '-';
export const renderPerson = cell => {
    const person = cell?.row[cell.field];
    if (!person?.name) return '-'
    const name = person.name.split(' ')
    return name[0][0] + ' ' + name[1]
}

export const dateGetter = cell => {
    return cell.value ? new Date(cell.value < 10000000000 ? cell.value * 1000 : cell.value) : null;
}
export const dateRenderer = cell => formatDate(cell.value);
export const bytesRenderer = cell => {
    if (cell.value === null) {
        return null
    }
    if (cell.value < 2000) {
        return `${cell.value} Bytes`
    }
    if (cell.value < 2000_000) {
        return `${cell.value >> 10} KiB`
    }
    return `${cell.value >> 20} MiB`
}

export const cellDefaults = {
    headerAlign: 'right',
    align: 'right',
    autosize: {
        includeOutliers: true,
        includeHeaders: true,
        expand: true,
    },
}

export const defaultDateCell = {
    ...cellDefaults,
    type: 'date',
    valueGetter: dateGetter,
    renderCell: dateRenderer,
    minWidth: 110,
}

export const defaultDateTimeCell = {
    ...cellDefaults,
    type: 'datetime',
    valueGetter: dateGetter,
    valueFormatter: (params) => dayjs(params.value).format('YYYY-MM-DDTHH:mm:ssZ'),
    minWidth: 110,
}

export const defaultBytesCell = {
    ...cellDefaults,
    type: 'number',
    valueFormatter: bytesRenderer,
    minWidth: 80,
}

export const defaultPersonCell = {
    ...cellDefaults,
    type: 'string',
    valueGetter: formatPerson,
    renderCell: renderPerson,
}

export const defaultMinutesCell = {
    ...cellDefaults,
    type: 'number',
    renderCell: minutesToTimeStr,
}

export const DataGrid = params => {
    const [wrapperStyle, setWrapperStyle] = useState({})
    const apiRef = useGridApiRef();

    const showExportButton = useMemo(() => {
        return !Boolean(params?.disableExport)
    }, [params?.disableExport])

    useEffect(() => {
        return setWrapperStyle(params.wrapperStyle || {height: 'calc(100dvh - 90px)'})
    }, [params?.wrapperStyle])

    const getSelectedRowsToExport = useCallback(({apiRef}) => {
        const selectedRowIds = selectedGridRowsSelector(apiRef);
        if (selectedRowIds.size > 0) {
            return Array.from(selectedRowIds.keys());
        }
        return gridFilteredSortedRowIdsSelector(apiRef);
    }, []);

    const {columns, rows, autosizeOptions} = params;
    useEffect(() => {
        if (autosizeOptions === null) return
        apiRef.current.autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
            expand: true,
        })
    }, [apiRef, autosizeOptions, columns, rows])

    const toolbar = params?.toolbar

    const genToolbar = useCallback(() => <GridToolbarContainer>
        {showExportButton ? <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> : ''}
        <GridToolbarDensitySelector/>
        <GridToolbarColumnsButton/>
        {toolbar}
    </GridToolbarContainer>, [toolbar, showExportButton])

    const passthrough = {...params};
    delete passthrough.toolbar;
    delete passthrough.wrapperStyle;

    return <div style={wrapperStyle}>
        <DataGridPro
            apiRef={apiRef}
            sx={{mt:0}}
            hideFooter={true}
            slots={{toolbar: genToolbar}}
            slotProps={{
                toolbar: {printOptions: {getRowsToExport: getSelectedRowsToExport}},
            }}
            autosizeOptions={{
                includeOutliers: true,
                includeHeaders: true,
                expand: true,
            }}
            {...passthrough}
        />
    </div>
}
export default DataGrid;
